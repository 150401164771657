import { cloneDeep, merge } from 'lodash';
import {
    Container,
    Content,
    Footer,
    Heading,
    Section,
    SectionItemsStyle,
    SectionLayout,
    TextStyle,
} from '@/entities/section/samples/_options';
import { SectionItem } from '@/services/services.types';

const section = {
    id: '',
    type: 'SectionCategoryBase',
    required: false,
    group: 'Category',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        itemsLayout: { ...SectionLayout },
        itemsStyle: {
            template: {
                ...SectionItemsStyle.createTemplate('style1', [
                    { text: 'style1', value: 'style1' },
                    { text: 'style2', value: 'style2' },
                    { text: 'style3', value: 'style3' },
                    { text: 'style4', value: 'style4' },
                    { text: 'style5', value: 'style5' },
                    { text: 'style6', value: 'style6' },
                    { text: 'style7', value: 'style7' },
                ]),
            },
            imageProportions: { ...SectionItemsStyle.imageProportions },
            borderRadius: { ...SectionItemsStyle.borderRadius },
        },
        footer: { ...Footer },
    },
    items: [],
    children: {
        id: '',
        type: 'benefit',
        template: null,
        options: {
            heading: {
                title: {
                    ...Heading.title,
                    value: 'Category Title',
                },
                colorTitle: { ...Heading.colorTitle },
                textAlign: { ...Heading.textAlign, value: 'left' },
                background: {
                    ...Heading.colorTitle,
                    props: {
                        labelTrans: 'builder.sectionOptions.props.background',
                        btnReset: true,
                        mode: 'hexa',
                    },
                    visibleCondition: (sectionItem: SectionItem) => {
                        const itemsStyle = sectionItem.type;
                        if (!itemsStyle) return false;
                        return ['style3'].includes(itemsStyle);
                    },
                },
            },
            content: {
                // link: { ...Content.categoryLink },
                link: { ...Content.categoryLinkCangeImage },
                image: merge(Content.image, {
                    props: { note: 'JPG, PNG. Recomended size: 400х400px. No more than 40kb' },
                }),
                note: {
                    ...Content.note,
                    visibleCondition: (sectionItem: SectionItem) => {
                        const itemsStyle = sectionItem.type;
                        if (!itemsStyle) return false;
                        return ['style6', 'style7'].includes(itemsStyle);
                    },
                },
                noteColor: {
                    ...TextStyle.color,
                    visibleCondition: (sectionItem: SectionItem) => {
                        const itemsStyle = sectionItem.type;
                        if (!itemsStyle) return false;
                        return ['style6', 'style7'].includes(itemsStyle);
                    },
                },
                showButton: {
                    ...Content.showButton,
                    visibleCondition: (sectionItem: SectionItem) => {
                        const itemsStyle = sectionItem.type;
                        if (!itemsStyle) return false;
                        return ['style7'].includes(itemsStyle);
                    },
                },
                btnText: {
                    ...Content.btnText,
                    visibleCondition: (sectionItem: SectionItem) => {
                        const itemsStyle = sectionItem.type;
                        if (!itemsStyle) return false;
                        return ['style7'].includes(itemsStyle);
                    },
                },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
section.items = itemsFixture;

export default section;
