import Vue from 'vue';
//При изменении проверять page-switcher в билдере и модалку опций в админке
Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
            if (!el.contains(event.target)) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
        if (binding.modifiers.stop) {
            const dynamicElement = el.querySelector(`.${binding.arg}`);
            if (!dynamicElement) return;
            dynamicElement.addEventListener('click', (e) => e.stopPropagation());
        }
    },

    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});
