import SettingService from '@/services/SettingService';
import defaultConfig from '@/configs/default';
import { uuid } from 'vue-uuid';

const state = {
    settings: defaultConfig,
    isLoaded: false,
};

const mutations = {
    SET_SETTINGS_DATA(state, payload) {
        const settings = {};
        // Merge default config with payload (id & value only)
        for (const [groupKey, groupValue] of Object.entries(defaultConfig)) {
            settings[groupKey] = groupValue;

            for (const [key, value] of Object.entries(groupValue)) {
                if (payload[groupKey] && payload[groupKey][key])
                    settings[groupKey][key] = Object.assign(value, {
                        id: payload[groupKey][key].id ? payload[groupKey][key].id : uuid.v4(),
                        value: payload[groupKey][key].value,
                    });
                else settings[groupKey][key].id = uuid.v4();
            }
        }
        // временный костыль
        if (typeof settings.common.address.value === 'string')
            settings.common.address.value = {
                country: '',
                region: '',
                locality: '',
                street: '',
                postalCode: '',
            };

        state.settings = settings;
        state.settings.common.address.value = null;
        state.isLoaded = true;
    },
};
const actions = {
    async loadSettings({ commit }) {
        const [error, settings] = await SettingService.getAll();
        if (!error) commit('SET_SETTINGS_DATA', settings);
        else error.notify();
    },
    async setSettings({ commit }, settings) {
        commit('SET_SETTINGS_DATA', settings);
    },
};

const getters = {
    getIsLoaded: (state) => state.isLoaded,
    getAllSettings: (state) => state.settings,
    getCurrency: (state) => state.settings.ecommerce.currency.value,
    getSeo: (state) => state.settings.seo,
    getServiceCartIsAvalible: (state) => {
        if (!state.settings?.common?.type) return false;
        return state.settings?.common?.type.value === 'services_cart' ? true : false;
    },
    getSitename: (state) => {
        if (state.settings && state.settings.common) return state.settings.common.sitename;
        else return 'Hello World';
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
