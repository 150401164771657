import { localStorageService } from '@/helpers/localStorageService';
import { Commit, Dispatch, GetterTree, Store } from 'vuex';

export interface IClipboardModuleState {
    type: 'section' | 'sections' | null;
    value: any;
}

const LOCALSTOREGE_KEY = 'runshop_store_clipboard';

const state: IClipboardModuleState = {
    type: null,
    value: null,
};

const getters = {
    getClipboard: (state: IClipboardModuleState): IClipboardModuleState => ({
        type: state.type,
        value: state.value,
    }),
    hasContent: (state: IClipboardModuleState): boolean => !!state.type && !!state.value,
};

const mutations = {
    SET_CONTENT(state: IClipboardModuleState, payload: any): void {
        state.type = payload?.type ? payload.type : null;
        state.value = payload?.value ? payload.value : null;
    },
    RESET_CONTENT(state: IClipboardModuleState): void {
        state.type = null;
        state.value = null;
    },
};

const actions: Store<any> | any = {
    loadClipboard({ commit }: { commit: Commit }) {
        const loadedData = localStorageService.getItem(LOCALSTOREGE_KEY);
        const state = JSON.parse(loadedData);
        commit('SET_CONTENT', state);
    },
    saveClipboard({ getters }: { getters: GetterTree<string, unknown> }): void {
        const state = getters['getClipboard'];
        localStorageService.setItem(LOCALSTOREGE_KEY, JSON.stringify(state));
    },
    copyToClipboard(
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        payload: IClipboardModuleState
    ): void {
        commit('SET_CONTENT', payload);
        dispatch('saveClipboard');
    },
    resetClipboard({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }): void {
        commit('RESET_CONTENT');
        dispatch('saveClipboard');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
