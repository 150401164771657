<template
    ><div class="input-align">
        <template v-for="(item, key) in activeButtons">
            <div
                :key="key"
                @click="model = item.value"
                v-if="item.isActive"
                :title="item.value"
                class="input-align__button"
                :class="{ '-is-selected': model === item.value }"
                :style="{ gridArea: item.gridArea }"
            >
                <span
                    class="material-icons-outlined"
                    v-if="key !== 'center'"
                    :style="{ transform: `rotate(${item.deg}deg)` }"
                    >arrow_upward</span
                >
                <span class="material-icons" v-else>fiber_manual_record</span>
            </div>
        </template>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
export default {
    name: 'InputAlign',
    mixins: [proxyModelMixin],
    props: {
        directions: {
            type: Array,
            default: () => ['vertical', 'horizontal', 'diagonal'],
            validator(val) {
                const values = ['vertical', 'horizontal', 'diagonal'];
                return val.every((item) => values.includes(item));
            },
        },
        value: {
            type: String,
            default: 'center',
        },
    },
    data() {
        return {
            buttons: {
                topLeft: {
                    value: 'top-left',
                    gridArea: 'tl',
                    group: 'diagonal',
                    deg: 315,
                },
                top: {
                    value: 'top',
                    gridArea: 't',
                    group: 'vertical',
                    deg: 0,
                },
                topRight: {
                    value: 'top-right',
                    gridArea: 'tr',
                    group: 'diagonal',
                    deg: 45,
                },
                right: {
                    value: 'right',
                    gridArea: 'r',
                    group: 'horizontal',
                    deg: 90,
                },
                bottomRight: {
                    value: 'bottom-right',
                    gridArea: 'br',
                    group: 'diagonal',
                    deg: 135,
                },
                bottom: {
                    value: 'bottom',
                    gridArea: 'b',
                    group: 'vertical',
                    deg: 180,
                },
                bottomLeft: {
                    value: 'bottom-left',
                    gridArea: 'bl',
                    group: 'diagonal',
                    deg: 225,
                },
                left: {
                    value: 'left',
                    gridArea: 'l',
                    group: 'horizontal',
                    deg: 270,
                },
                center: {
                    value: 'center',
                    gridArea: 'c',
                    isActive: true,
                },
            },
        };
    },
    computed: {
        activeButtons() {
            const result = cloneDeep(this.buttons);
            for (const key in result) {
                if (this.directions.includes(result[key].group)) {
                    result[key].isActive = true;
                }
            }
            return result;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.input-align {
    display: grid;
    padding: 0 45px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr min-content;
    grid-template-areas:
        'tl t tr'
        'l c r'
        'bl b br';
    gap: 6px;
    justify-content: space-between;

    &__button {
        border: 1px solid var(--v-outline-base);
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: var(--v-surface-base);
        cursor: pointer;
        transform: translateZ(0);
        transition: background-color 0.2s;
        &.-is-selected {
            transform: scale(0.94);
            color: var(--v-primary-base);
            background-color: var(--v-primary-lighten-base);
            border-color: var(--v-primary-base);
            border-width: 2px;
            cursor: default;
            &:active {
                transform: scale(0.94);
                border-width: 2px;
                border-color: var(--v-primary-base);
            }
        }
        .material-icons-outlined,
        .material-icons {
            font-size: 24px;
        }
        &:hover {
            background-color: var(--v-primary-lighten-base);
        }
        &:active {
            transition: transform 0.05s;
            border-color: var(--v-primary-accent-base);
            transform: scale(0.9);
        }
    }
}
</style>
