import { cloneDeep, isEmpty } from 'lodash';
import { diff } from 'deep-object-diff';
import { uuid } from 'vue-uuid';
import EventEmitter from '@/helpers/eventEmitter.ts';
import PageService from '@/services/PageService.ts';
import CatalogService from '@/services/CatalogService.ts';
import ProductService from '@/services/ProductService.ts';
import TextPage from '@/entities/page/TextPage';
import Catalog from '@/entities/catalog/Catalog';
import SettingService from '@/services/SettingService';
import defaultConfig from '@/configs/default';
import { HistoryTypes } from '@/store/Types.js';
import Section from '@/entities/section/Section';
import SettingItem from '@/entities/setting/SettingItem';
import Homepage from '@/entities/page/Homepage';
import Product from '@/entities/product/Product';
import { difference } from '@/helpers/utils';
import CategoryService from '@/services/CategoryService';
import Category from '@/entities/blog/Category';
import ArticleService from '@/services/ArticleService';
import Article from '@/entities/blog/Article';

const pageTypes = {
    homepage: {
        get: PageService.getHomepage.bind(PageService),
        update: PageService.updateHomepage.bind(ProductService),
        constructor: Homepage,
    },
    textpage: {
        get: PageService.getOne.bind(PageService),
        create: PageService.createOne.bind(PageService),
        update: PageService.updateOne.bind(PageService),
        constructor: TextPage,
    },
    catalog: {
        get: CatalogService.getOne.bind(CatalogService),
        create: CatalogService.createOne.bind(CatalogService),
        update: CatalogService.updateOne.bind(CatalogService),
        constructor: Catalog,
    },
    product: {
        get: ProductService.getOne.bind(ProductService),
        create: ProductService.createOne.bind(ProductService),
        update: ProductService.updateOne.bind(ProductService),
        constructor: Product,
    },
    category: {
        get: CategoryService.getOne.bind(CategoryService),
        create: CategoryService.createOne.bind(CategoryService),
        update: CategoryService.updateOne.bind(CategoryService),
        constructor: Category,
    },
    article: {
        get: ArticleService.getOne.bind(ArticleService),
        create: ArticleService.createOne.bind(ArticleService),
        update: ArticleService.updateOne.bind(ArticleService),
        constructor: Article,
    },
};

const state = {
    currentPage: null,
    initialSettings: null,
    settings: null,
    isHistory: true,
    isNewPage: false,
    previewMode: 'desktop',
    newSectionListVisible: false,
    validateStatus: true,
    activeItem: {
        page: null,
        section: null,
        sectionItem: null,
        settings: null,
        utilPage: null,
    },
    activeSectionsGroup: null,
    currentSidebar: null,
};

const mutations = {
    setCurrentPage(state, payload) {
        state.currentPage = payload;
    },
    setPreviewMode(state, payload) {
        state.previewMode = payload;
    },
    setSettings(state, payload) {
        state.settings = payload;
    },
    setInitialSettings(state, payload) {
        state.initialSettings = cloneDeep(payload);
    },
    setIsNewPage(state, payload) {
        state.isNewPage = payload;
    },
    updateActiveItem(state, payload) {
        state.activeItem = payload;
    },
    updateActiveSectionsGroup(state, payload) {
        state.activeSectionsGroup = payload;
    },
    updatePage(state, payload) {
        state.currentPage = payload;
    },
    updateSection(state, updatedSection) {
        const section = state.currentPage.sections.find((section) => section.id === updatedSection.id);
        if (!section) return;
        Object.keys(section).forEach((prop) => {
            if (updatedSection[prop]) section[prop] = updatedSection[prop];
        });
    },
    updateSectionItem(state, { parentID, updatedSectionItem }) {
        const section = state.currentPage.sections.find((section) => section.id === parentID);
        if (!section) return;
        const sectionItem = section.items.find((item) => item.id === updatedSectionItem.id);
        if (sectionItem) {
            Object.keys(sectionItem).forEach((prop) => {
                if (updatedSectionItem[prop]) sectionItem[prop] = updatedSectionItem[prop];
            });
        }
    },
    updateSections(state, payload) {
        state.currentPage.sections = payload;
        state.currentPage.sections.forEach((section, index) => (section.position = index));
    },
    updateSettings(state, payload) {
        state.settings = payload;
    },
    updateValidateStatus(state, payload) {
        state.validateStatus = payload;
    },
    updateNewSectionListVisible(state, visibility) {
        state.newSectionListVisible = visibility;
    },
    updateFromHistory(state, payload) {
        state.isHistory = false;
        state.currentPage = payload.page;
        state.settings = payload.settings;
        // пока что закостылил таймаут, чтобы watch в компонентах при обновление стейта не создавал новую точку истории
        setTimeout(() => {
            state.isHistory = true;
        }, 310);
    },
    removeSection(state, sectionId) {
        const section = state.currentPage.sections.find((section) => section.id === sectionId);
        state.currentPage.sections.splice(state.currentPage.sections.indexOf(section), 1);
    },
    setCurrentSidebar(state, sidebar) {
        state.currentSidebar = { ...sidebar };
    },
};

const actions = {
    // COMMON
    async loadPage({ commit, dispatch }, { type, id, categoryId }) {
        let page, error;
        // загружаемые с апи страницы
        if (type === 'homepage') [error, page] = await pageTypes[type]?.get(id);
        if (type === 'textpage' && id) [error, page] = await pageTypes[type]?.get(id);
        if (type === 'catalog' && id) [error, page] = await pageTypes[type]?.get(id);
        if (type === 'product' && id) [error, page] = await pageTypes[type]?.get(id);
        if (type === 'media' && id && !categoryId) [error, page] = await pageTypes.category.get(id);
        if (type === 'media' && id && categoryId) [error, page] = await pageTypes.article.get(id);
        if (error) {
            error.alert();
        }
        // создание новых страниц
        if (type === 'textpage' && !id) page = new TextPage();
        if (type === 'catalog' && !id) page = new Catalog();
        let entity = null;
        if (pageTypes[type]) {
            entity = new pageTypes[type].constructor(page);
        } else if (type === 'media') {
            entity = categoryId ? new Article(page) : new Category(page);
        }
        commit('setCurrentPage', entity);
        // Уууу, костылище
        for (let i = 1; i < 5; i++) {
            setTimeout(() => {
                dispatch(HistoryTypes.actions.RESET_HISTORY, null, { root: true });
            }, i * 100);
        }
    },
    async loadSettings({ commit }) {
        const [error, loadedSettings] = await SettingService.getAll();
        if (error) {
            error.alert();
        }
        const settings = {};
        // Merge default config with payload (id & value only)
        for (const [groupKey, groupValue] of Object.entries(defaultConfig)) {
            settings[groupKey] = cloneDeep(groupValue);

            for (const [key, value] of Object.entries(groupValue)) {
                if (loadedSettings[groupKey] && loadedSettings[groupKey][key])
                    settings[groupKey][key] = Object.assign(value, {
                        id: loadedSettings[groupKey][key].id ? loadedSettings[groupKey][key].id : uuid.v4(),
                        value: loadedSettings[groupKey][key].value,
                    });
                else settings[groupKey][key].id = uuid.v4();
            }
        }

        // временный костыль
        if (typeof settings.common.address.value === 'string')
            settings.common.address.value = {
                country: '',
                region: '',
                locality: '',
                street: '',
                postalCode: '',
            };

        commit('setSettings', settings);
        commit('setInitialSettings', settings);
    },
    async savePage({ state }) {
        const type = state.currentPage.type;
        const action = state.isNewPage ? 'create' : 'update';
        if (['homepage', 'textpage', 'catalog', 'category', 'article'].includes(type)) {
            return await pageTypes[type][action](state.currentPage.data).then((result) => result);
        }
    },
    async saveSetting({ state }) {
        const groups = Object.keys(difference(state.settings, state.initialSettings));
        if (!groups.length) return [null, null];
        const settings = {};
        groups.forEach((key) => {
            settings[key] = {};
            Object.keys(state.settings[key]).forEach((item) => {
                const value = state.settings[key][item];
                settings[key][item] = new SettingItem(value);
            });
        });
        return await SettingService.updateBatch(groups, settings).then((result) => result);
    },
    async saveCurrent({ dispatch, state }) {
        let isSuccess = true;
        if (!state.validateStatus) {
            EventEmitter.sendNotyError('Invalid values');
        } else {
            const validPageTypes = ['homepage', 'textpage', 'catalog', 'category', 'article'];
            const canSavePage = validPageTypes.includes(state.currentPage?.type);
            const canSaveSettings = !!Object.keys(difference(state.settings, state.initialSettings)).length;
            const saves = [];
            if (canSavePage) saves.push(dispatch('savePage'));
            if (canSaveSettings) saves.push(dispatch('saveSetting'));
            return Promise.all(saves).then((results) => {
                results.forEach((value) => {
                    const [error] = value;
                    if (error) {
                        error.notify();
                        isSuccess = false;
                    }
                });
                // при успешном сохранение
                if (isSuccess) dispatch(HistoryTypes.actions.RESET_HISTORY, null, { root: true });
                return {
                    success: isSuccess,
                    data: results.map((result) => result[1]),
                };
            });
        }
    },
    addHistoryPoint({ state, dispatch, rootGetters }) {
        const action = HistoryTypes.actions.UPDATE_HISTORY;
        const payload = {
            page: state.currentPage,
            settings: state.settings,
        };
        const history = {
            page: rootGetters['history/CURRENT_PAGE'],
            settings: rootGetters['history/CURRENT_SETTINGS'],
        };
        const historyDiff = diff(payload, history);
        const hasChanges = !isEmpty(historyDiff);
        // добавлем точку истории, только если есть различия
        if (hasChanges) dispatch(action, cloneDeep(payload), { root: true });
    },
    updateActiveItem({ commit, state }, { type, data }) {
        let currentActive = cloneDeep(state.activeItem);
        for (const [key] of Object.entries(currentActive)) {
            if (key === type) currentActive[key] = data;
            else currentActive[key] = null;
        }
        commit('updateActiveItem', currentActive);
        if (type === 'section') EventEmitter.presets.public.scrollTo(data);
    },
    updateActiveSectionsGroup({ commit }, group) {
        commit('updateActiveSectionsGroup', group);
    },
    updatePage({ commit, dispatch }, page) {
        commit('updatePage', page);
        dispatch('addHistoryPoint');
        EventEmitter.presets.public.updatePageOptions(cloneDeep(page));
    },
    updateInitialSettings({ commit, state }) {
        commit('setInitialSettings', state.settings);
    },
    updateIsNewPage({ commit }, payload) {
        commit('setIsNewPage', payload);
    },
    updateSettings({ commit, dispatch }, settings) {
        commit('updateSettings', settings);
        dispatch('addHistoryPoint');
        // костыль, ругается на копирование функции в events у mapUrl src/configs/groups/common.js
        let updatedSettings = cloneDeep(settings);
        if (updatedSettings?.common?.mapUrl?.events) updatedSettings.common.mapUrl.events = null;
        EventEmitter.presets.public.updateSettings(updatedSettings);
    },
    updateValidateStatus({ commit }, status) {
        commit('updateValidateStatus', status);
    },

    // SECTIONS
    addSection({ getters, commit, dispatch }, section) {
        const newSection = new Section(section);
        newSection.position = getters.getSectionsCurrentPage.length;
        const updatedSections = [...getters.getSectionsCurrentPage, ...[newSection]];
        commit('updateSections', updatedSections);
        dispatch('addHistoryPoint');
        EventEmitter.presets.public.addSection(newSection.data);
        EventEmitter.presets.public.scrollTo(newSection.id);
        dispatch('updateActiveItem', { type: 'section', data: newSection.id });
    },
    addSectionItem({ dispatch }, section) {
        section.addChildren();
        dispatch('addHistoryPoint');
        EventEmitter.presets.public.updateSection(section.data);
    },
    dublicateSectionItem({ state, dispatch }, sectionItem) {
        const parentSection = state.currentPage.sections.find((section) => section.hasChildWithId(sectionItem.id));
        if (!parentSection) {
            EventEmitter.sendNotyError('Something wrong');
            return;
        }
        const newItem = cloneDeep(sectionItem);
        newItem.id = uuid.v4();
        newItem.position = parentSection.items.length - 1;
        parentSection.items.push(newItem);
        dispatch('addHistoryPoint');
        EventEmitter.presets.public.updateSection(parentSection.data);
    },
    updateSection({ commit, dispatch, state }, updateSection) {
        if (updateSection) {
            commit('updateSection', updateSection);
            dispatch('addHistoryPoint');
            EventEmitter.presets.public.updateSection(updateSection.data);
            if (state.isNewPage) EventEmitter.presets.public.updateSection(updateSection.data);
        }
    },
    updateSectionItem({ commit, dispatch, state }, updatedSectionItem) {
        let parentSection;
        state.currentPage.sections.forEach((section) => {
            const sectionItem = section.items.find((item) => item.id === updatedSectionItem.id);
            if (sectionItem) {
                parentSection = section;
                commit('updateSectionItem', { parentID: parentSection.id, updatedSectionItem });
                dispatch('addHistoryPoint');
            }
        });
        EventEmitter.presets.public.updateSection(parentSection.data);
    },
    removeSection({ commit, dispatch }, sectionId) {
        commit('removeSection', sectionId);
        dispatch('addHistoryPoint');
        EventEmitter.presets.public.removeSection(sectionId);
    },
    removeSectionItem({ dispatch }, sectionItemId) {
        const parentSection = state.currentPage.sections.find((section) => section.hasChildWithId(sectionItemId));
        if (!parentSection) {
            EventEmitter.sendNotyError('Something wrong');
            return;
        }
        const item = parentSection.items.find((item) => item.id === sectionItemId);
        parentSection.items.splice(parentSection.items.indexOf(item), 1);
        dispatch('addHistoryPoint');
        EventEmitter.presets.public.updateSection(parentSection.data);
    },
    updateSectionPositions({ state }, currentId) {
        const newSectionPosition = state.currentPage.sections.map((section) => ({
            id: section.id,
            position: section.position,
        }));
        EventEmitter.presets.public.updateSectionPositions(newSectionPosition);
        if (currentId) EventEmitter.presets.public.scrollTo(currentId);
    },
    updateCurrentSidebar({ commit }, sidebar) {
        commit('setCurrentSidebar', sidebar);
    },
};

const getters = {
    isPreviewModeFullscreen: (state) => state.previewMode === 'fullscreen',
    isSidebarRightShow: (state) => {
        let result = false;
        for (const [key] of Object.entries(state.activeItem)) {
            if (state.activeItem[key]) result = true;
        }
        return result;
    },
    isShowNewSectionList: (state) => state.newSectionListVisible,
    getCurrentPage: (state) => state.currentPage,
    getSettings: (state) => state.settings,
    getActiveItem: (state) => state.activeItem,
    getSectionsCurrentPage: (state) => (state.currentPage ? state.currentPage.sections : []),
    getSidebarRightCurrent: (state) => {
        let type = null;
        for (const [key] of Object.entries(state.activeItem)) {
            if (state.activeItem[key]) type = key;
        }
        switch (type) {
            case 'page':
                return state.currentPage;
            case 'section': {
                const sections = state.currentPage ? state.currentPage.sections : [];
                return sections.find((section) => section.id === state.activeItem[type]);
            }
            case 'sectionItem': {
                let result = null;
                const sections = state.currentPage ? state.currentPage.sections : [];
                sections.forEach((section) => {
                    section.items.forEach((item) => {
                        if (item.id === state.activeItem.sectionItem) result = item;
                    });
                });
                return result;
            }
            case 'block':
                return 'block';
            case 'settings':
                return state.settings;
            default:
                return null;
        }
    },
    getSidebarRightCurrentType: (state) => {
        let type = null;
        for (const [key] of Object.entries(state.activeItem)) {
            if (state.activeItem[key]) type = key;
        }
        return type;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
