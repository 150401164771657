import { Page } from '@/services/services.types';
export type handler = (event: Event) => void;

export interface IEventEmitter {
    presets: {
        public: {
            removeSection: (sectionId: string) => void;
            updateMenu: (menuId: string) => void;
            updatePageOptions: (page: Page) => void;
            addSection: (sectionData: { [key: string]: unknown }) => void;
            updateSectionPositions: (newSectionPosition: { [key: string]: unknown }[]) => void;
            updateSection: (sectionData: { [key: string]: unknown }) => void;
            updateSettings: (settings: { [key: string]: unknown }) => void;
            scrollTo: (sectionId: string) => void;
        };
    };
    sendToPublic: (action: string, payload: { [key: string]: unknown }, timeout?: number) => void;
    sendNotyError: (text: string) => void;
    trigger: (eventName: string, ...args: any) => void;
    off: (eventName: string, handler: handler) => void;
    on: (eventName: string, handler: handler) => void;
}

const EventEmitter = (() => {
    const events = {} as { [key: string]: handler[] };

    /** Subscribe event, usage: menu.on('select', function(item) { ... } */
    const on = (eventName: string, handler: handler): void => {
        if (typeof handler !== 'function') return;
        if (!events[eventName]) events[eventName] = [];
        events[eventName].push(handler);
    };

    /** Unsubscribe event, usage: menu.off('select', handler) */
    const off = (eventName: string, handler: handler): void => {
        const handlers = events && events[eventName];
        if (!handlers) return;
        for (let i = 0; i < handlers.length; i++) {
            if (handlers[i] === handler) handlers.splice(i--, 1);
        }
    };

    /** Trigger event with name and data, usage: this.trigger('select', data1, data2); */
    const trigger = (eventName: string, ...args: any): void => {
        // обработчиков для этого события нет
        if (!events || !events[eventName]) return;
        // вызовем обработчики
        events[eventName].forEach((handler) => handler.apply(this, args));
    };

    const sendToPublic = (action: string, payload: { [key: string]: unknown }, timeout = 0): void => {
        trigger('to-public', {
            action: action,
            payload: payload,
            timeout: timeout,
        });
    };

    const sendNotyError = (text: string): void => {
        trigger('show-noty', {
            type: 'error',
            text: text,
        });
    };

    const presetsPublic = {
        // Common Events
        updatePageOptions: (page: Page) => sendToPublic('update-page-options', { data: page?.data ? page.data : page }),
        updateSettings: (settings: { [key: string]: unknown }) => sendToPublic('update-settings', { data: settings }),
        updateMenu: (menuId: string) => sendToPublic('update-menu', { menuId: menuId }),
        // Sections Events
        addSection: (sectionData: { [key: string]: unknown }) => sendToPublic('add-section', { data: sectionData }),
        updateSection: (sectionData: { [key: string]: unknown }) =>
            sendToPublic('update-section', { data: sectionData }),
        removeSection: (sectionId: string) => sendToPublic('remove-section', { sectionId: sectionId }),
        updateSectionPositions: (newSectionPosition: { [key: string]: unknown }[]) =>
            sendToPublic('update-section-position', {
                positions: newSectionPosition,
            }),
        // UtilityEvents
        scrollTo: (sectionId: string) => sendToPublic('scroll-to', { elementId: sectionId }, 50),
    };

    return Object.freeze({
        on,
        off,
        trigger,
        sendToPublic,
        sendNotyError,
        presets: {
            public: presetsPublic,
        },
    });
})();

export default EventEmitter;
