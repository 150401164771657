// eslint-disable-next-line
// @ts-nocheck
import { uuid } from 'vue-uuid';
import initFields from '@/entities/blog/Category.fields';
import Section from '../section/Section';
import Page from '@/entities/page/Page';
import Redirect from '@/entities/Redirect';
export default class Category extends Page {
    id: string;
    previewImageId: string;
    enabled: boolean;
    hidden: boolean;
    noIndex: boolean;
    title: string;
    heading: string;
    updatedAt: string;
    slug: string;
    metaTitle: string;
    metaDescription: string;
    sections: Section[];
    articleIds: string[];
    redirect: Redirect | null;
    fields: any;
    type = 'category';
    constructor({
        id = uuid.v4(),
        heading = '',
        enabled = true,
        hidden = false,
        noIndex = false,
        title = '',
        updatedAt = '',
        slug = '',
        metaTitle = '',
        metaDescription = '',
        sections = [],
        previewImageId = '',
        articleIds = [],
        redirect = null,
    } = {}) {
        super({
            id,
            title,
            heading,
            previewImageId,
            enabled,
            hidden,
            noIndex,
            slug,
            metaTitle,
            metaDescription,
            sections,
            redirect,
            defaultSections: ['SectionBreadcrumbs', 'SectionHeadingH1', 'SectionMediaTagsList', 'SectionMediaList'],
        });
        this.updatedAt = updatedAt;
        this.articleIds = articleIds;
        this.fields = initFields(this);
    }

    get data(): Category {
        return {
            id: this.id,
            heading: this.heading,
            previewImageId: this.previewImageId,
            enabled: this.enabled,
            hidden: this.hidden,
            noIndex: this.noIndex,
            title: this.title,
            slug: this.slug,
            articleIds: this.articleIds,
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            sections: this.sections.map((section) => section.data),
            redirect: this.redirect,
        };
    }
}
