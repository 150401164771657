import {
    addImage,
    createRedirectOnSave,
    deleteImage,
    deleteNewImage,
    addNewCatalogs,
    removeNewCatalogs,
    addNewLabels,
    removeNewLabels,
    handleSizeInput,
    handleTitleChange,
    updateCurrentImages,
    updateImages,
    validateSizeInput,
} from '@/entities/product/ProductHelper';
import { cloneDeep, debounce } from 'lodash';

export default (ctx) => ({
    title: {
        type: 'field',
        value: ctx.title,
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            class: '-half-width',
            label: 'entities.product.fields.title',
        },
        events: {
            input: debounce(function(title) {
                return handleTitleChange.call(this, title);
            }, 1000),
        },
    },
    slug: {
        type: 'field',
        value: ctx.slug,
        component: 'input-url',
        props: {
            rules: ['noEmpty'],
            pageType: 'product',
            label: 'entities.product.fields.slug',
        },
        events: {
            checkRedirect: createRedirectOnSave,
        },
    },
    heading: {
        type: 'field',
        component: 'input-text',
        value: ctx.heading,
        props: {
            // rules: ['noEmpty'],

            label: 'entities.product.fields.heading',
            class: '-half-width',
        },
    },
    description: {
        value: ctx.description,
        type: 'field',
        component: 'code-editor',
        props: {
            tooltip: 'product-description',
            tooltipWidth: 1000,
            label: 'entities.product.fields.shortDescription',
        },
    },
    previewDescription: {
        value: ctx.previewDescription,
        type: 'field',
        component: 'code-editor',
        props: {
            tooltip: 'product-preview-description',
            tooltipWidth: 1000,
            label: 'entities.product.fields.listingDescription',
        },
    },
    text: {
        value: ctx.text,
        type: 'field',
        component: 'code-editor',
        props: {
            label: 'entities.product.fields.fullDescription',
            allowVideo: true,
        },
    },
    catalogs: {
        value: cloneDeep(ctx.catalogs),
        type: 'field',
        component: 'combobox-multiple',
        props: {
            label: 'entities.product.fields.catalogs',
            class: '-half-width',
            items: [],
            returnObject: false,
            'item-text': (catalog) => catalog.title,
            'item-value': (catalog) => catalog.id,
        },
        events: {
            'select-item': addNewCatalogs,
            'remove-item': removeNewCatalogs,
        },
    },
    labelIds: {
        value: cloneDeep(ctx.labelIds),
        type: 'field',
        component: 'combobox-multiple',
        props: {
            label: 'entities.product.fields.labelIds',
            class: '-half-width',
            items: [],
            returnObject: false,
            'item-text': (label) => label.title,
            'item-value': (label) => label.id,
        },
        events: {
            'select-item': addNewLabels,
            'remove-item': removeNewLabels,
        },
    },
    images: {
        type: 'field',
        value: cloneDeep(ctx.images),
        component: 'product-image-manager',
        props: {},
        events: {
            change: updateCurrentImages,
            'delete-new-image': deleteNewImage,
            'add-image': addImage,
            'delete-image': deleteImage,
            'update-images': updateImages,
        },
    },
    metaTitle: {
        type: 'field',
        component: 'input-text',
        value: ctx.metaTitle,
        props: {
            label: 'entities.product.fields.metaTitle',
        },
    },
    metaDescription: {
        type: 'field',
        component: 'Textarea',
        value: ctx.metaDescription,
        props: {
            label: 'entities.product.fields.metaDescription',
        },
    },
    enabled: {
        value: ctx.enabled,
        type: 'field',
        component: 'input-switch',
        props: {},
    },
    hidden: {
        value: ctx.hidden,
        type: 'field',
        component: 'input-switch',
        props: {},
    },
    noIndex: {
        value: ctx.noIndex,
        type: 'field',
        component: 'input-switch',
        props: {},
    },
    variations: {
        value: ctx.variations,
        type: 'field',
        component: 'product-variations-list',
        props: { errors: [] },
    },
    options: {
        value: ctx.options,
        type: 'field',
        component: 'product-options-list',
        props: { errors: [] },
    },
    properties: {
        value: ctx.properties,
        type: 'field',
        component: 'product-property-list',
        props: { errors: [] },
    },
    size: {
        value: ctx.size,
        type: 'field',
        component: 'product-size-input',
        props: {
            errors: [],
            class: '-half-width',
            validateFunction: validateSizeInput,
            label: 'entities.product.fields.size',
            tooltip: 'product-size',
        },
        events: {
            input: (value) => handleSizeInput(value, ctx),
        },
    },
    weight: {
        value: ctx.weight,
        type: 'field',
        component: 'input-text',
        props: {
            errors: [],
            class: '-half-width',
            label: 'entities.product.fields.weight',
        },
    },
});
