import { cloneDeep, merge } from 'lodash';
//test
import {
    Container,
    Content,
    Footer,
    Heading,
    Section,
    SectionItemsStyle,
    SectionLayout,
} from '@/entities/section/samples/_options';
import { SectionItem } from '@/services/services.types';

const section = {
    id: '',
    type: 'SectionGalleryBase',
    required: false,
    group: 'Gallery',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        itemsLayout: {
            ...SectionLayout,
            props: {
                types: ['grid', 'list', 'carousel', 'masonry'],
            },
        },
        itemsStyle: {
            template: {
                ...SectionItemsStyle.createTemplate('style1', [
                    { text: 'style1', value: 'style1' },
                    { text: 'style2', value: 'style2' },
                    { text: 'style3', value: 'style3' },
                ]),
            },
            imageProportions: {
                ...SectionItemsStyle.imageProportions,
                visibleCondition: (sectionGroup: any, device: 'desktop' | 'tablet' | 'mobile') => {
                    return sectionGroup.options.itemsLayout.value[device]?.type !== 'masonry';
                },
                ...{
                    props: {
                        translate: true,
                        labelTrans: 'builder.sectionOptions.props.imageProportions',
                        items: [
                            { text: '1x1', value: 'img-1x1' },
                            { text: '16x9', value: 'img-16x9' },
                            { text: '4x3', value: 'img-4x3' },
                            { text: '2x1', value: 'img-2x1' },
                            { text: '3x1', value: 'img-3x1' },
                            { text: '3x2', value: 'img-3x2' },
                            { text: '2x3', value: 'img-2x3' },
                            { text: '3x4', value: 'img-3x4' },
                            { text: '4x5', value: 'img-4x5' },
                            { text: '3x5', value: 'img-3x5' },
                        ],
                    },
                },
            },
            borderRadius: { ...SectionItemsStyle.borderRadius },
        },
        footer: { ...Footer },
    },
    items: [],
    children: {
        id: '',
        type: 'galleryItem',
        template: null,
        options: {
            content: {
                image: merge(Content.image, {
                    props: { note: 'JPG, PNG. Recomended size: 550х550px. No more than 50kb' },
                }),
                alt: {
                    ...Content.imageAlt,
                    value: 'Image title text',
                },
                title: {
                    ...Content.imageTitle,
                    value: 'Image title text',
                },
                note: {
                    ...Content.note,
                },
                link: {
                    ...Content.link,
                    visibleCondition: (sectionItem: SectionItem) => {
                        const itemsStyle = sectionItem.type;
                        if (!itemsStyle) return false;
                        return ['style1'].includes(itemsStyle);
                    },
                },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 3 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
section.items = itemsFixture;

export default section;
