import { cloneDeep } from 'lodash';
import {
    Container,
    Content,
    Footer,
    Heading,
    Section,
    SectionItemsStyle,
    SectionLayout,
} from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionTestimonialBase',
    required: false,
    group: 'Testimonial',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        itemsLayout: {
            ...SectionLayout,
            props: {
                types: ['grid', 'list', 'carousel'],
            },
        },
        itemsStyle: {
            template: {
                ...SectionItemsStyle.createTemplate('style1', [{ text: 'style1', value: 'style1' }]),
            },
        },
        footer: { ...Footer },
    },
    items: [],
    children: {
        id: '',
        type: 'testimonial',
        template: null,
        options: {
            content: {
                rating: { ...Content.rating },
                text: { ...Content.text },
                author: {
                    ...Content.textSmall,
                    props: {
                        labelTrans: 'builder.sectionOptions.props.author',
                    },
                },
                avatar: {
                    ...Content.image,
                    props: {
                        labelTrans: 'builder.sectionOptions.props.avatar',
                        note: 'JPG, PNG. Recomended size: 80х80.',
                    },
                },
                horizontalAlign: {
                    ...Content.horizontalAlign,
                },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 3 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
section.items = itemsFixture;

export default section;
