import { cloneDeep } from 'lodash';
import {
    Container,
    Content,
    Footer,
    Heading,
    Section,
    SectionItemsStyle,
    SectionLayout,
} from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionPropertyBase',
    required: false,
    group: 'Properties',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        itemsLayout: {
            ...SectionLayout,
            props: {
                types: ['grid', 'list', 'centeredColumns'],
            },
        },
        itemsStyle: {
            template: {
                ...SectionItemsStyle.createTemplate('style1', [
                    { text: 'style1', value: 'style1' },
                    { text: 'style2', value: 'style2' },
                    { text: 'style3', value: 'style3' },
                    { text: 'style4', value: 'style4' },
                ]),
            },
        },
        footer: { ...Footer },
    },
    items: [],
    children: {
        id: '',
        type: 'property',
        template: null,
        options: {
            heading: {
                title: {
                    ...Heading.title,
                    value: 'property name',
                },
                color: Heading.colorTitle,
            },
            content: {
                text: {
                    ...Content.textSmall,
                    value: 'property value',
                },
                color: Content.color,
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 3 }));
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore start
section.items = itemsFixture;

export default section;
