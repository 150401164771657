<template>
    <div class="color-picker">
        <div class="color-picker__color" @click.prevent="initPicker(false)" :style="`background: ${model}`">
            <div class="color-picker__color-input" ref="palete"></div>
        </div>
        <input v-model="model" type="text" class="color-picker__input" pattern="#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" />
        <Button v-if="hasCurrent" class="color-picker__btn-clear" type="icon" icon="cancel" @click="clearCurrent" />
        <Button v-if="hasPipette" class="color-picker__btn-pipette" type="icon" icon="colorize" @click="usePipette" />
    </div>
</template>

<script>
import { debounce } from 'lodash';
import Button from '@/components/common/Button.vue';
import '@simonwep/pickr/dist/themes/monolith.min.css';
import Pickr from '@simonwep/pickr/dist/pickr.es5.min';

export default {
    name: 'ColorPicker',
    components: { Button },
    props: {
        value: { type: [String, null], default: null },
        name: { type: String },
        group: { type: String },
    },
    data() {
        return {
            // eslint-disable-next-line
            eyeDropper: window.EyeDropper ? new EyeDropper() : null,
            pickr: null,
            options: {
                theme: 'monolith',
                position: 'bottom-start',
                components: {
                    // Main components
                    // preview: true,
                    hue: true,
                    interaction: {
                        // save: true,
                        // input: true,
                        // clear: true,
                    },
                },
            },
            isInit: false,
        };
    },
    computed: {
        model: {
            get() {
                return this.value ? this.value : '';
            },
            set(value) {
                this.inputDebounced(value);
            },
        },
        current() {
            return this.model ? this.model : 'Not selected';
        },
        hasCurrent() {
            return !!this.model;
        },
        hasPipette() {
            return !!this.eyeDropper;
        },
    },
    methods: {
        clearCurrent() {
            this.model = '#FFFFFF';
            if (this.isInit) {
                this.pickr.setColor('#ffffff');
                this.pickr.applyColor();
            }
        },
        togglePicker() {
            this.$refs.picker.close();
        },
        inputDebounced: debounce(function(value) {
            this.$emit('input', value);
        }, 100),
        usePipette() {
            this.eyeDropper
                .open()
                .then((result) => {
                    if (!this.isInit) this.initPicker(true);
                    this.model = result.sRGBHex;
                    this.pickr.setColor(result.sRGBHex);
                    this.pickr.applyColor();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        initPicker(hide) {
            if (this.isInit) return;
            this.pickr = new Pickr({
                el: this.$refs.palete,
                default: this.model ? this.model : '#ffffff',
                ...this.options,
            });
            if (!hide) this.pickr.show();
            this.pickr.on('change', (color) => {
                if (color) this.model = color.toHEXA().toString();
            });
            this.isInit = true;
        },
    },
    watch: {
        value: function(newValue) {
            if (newValue && this.pickr) {
                this.pickr.setColor(newValue);
                this.pickr.applyColor();
            }
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.color-picker {
    position: relative;
    border: $form-control-border;
    border-radius: $form-control-border-radius;
    overflow: hidden;
    background: $form-control-bg;
    transition: $form-control-transition;
    &__color {
        position: absolute;
        left: 16px;
        top: 8px;
        display: block;
        padding: 0;
        border: 1px solid var(--v-outline-base);
        border-radius: 4px;
        background: no-repeat;
        width: 32px;
        height: 24px;
        cursor: pointer;
        &-input {
            width: 32px;
            height: 24px;
            opacity: 0;
        }
        .pickr {
            width: 30px;
            height: 22px;
        }
        .pcr-button {
            width: 30px;
            height: 22px;
        }
    }
    &__popup {
        border: 1px solid var(--v-outline-base);
    }
    &__input {
        display: block;
        padding: 8px 16px 8px 56px;
        width: auto;
        border: none !important;
        outline: none !important;
        height: 40px;
        background: $form-control-bg;
        transition: $form-control-transition;
        &:invalid {
            background: var(--v-error-base);
        }
    }
    &:hover {
        border-color: var(--v-on-surface-medium-base);
    }
    &__btn-clear {
        position: absolute;
        right: 3px;
        top: 2px;
    }
    &__btn-pipette {
        position: absolute;
        right: 3px;
        top: 2px;
    }
    &__btn-clear + &__btn-pipette {
        right: 36px;
    }
}
.pcr-color-chooser {
    margin-top: 10px !important;
}
.pcr-app {
    transform: translateX(67px);
}
</style>
