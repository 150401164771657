import CatalogEntry from '@/entities/catalog/CatalogEntry';
//import RedirectService from '@/services/RedirectService';
import ImageService from '@/services/ImageService';
import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Catalog, CatalogEntryType, Image, Product, SortingRequestParams } from '@/services/services.types';

class CatalogService {
    /**
     * Создание связи каталог-товар
     * @param {String} catalogId - ID каталога для которого создается связь с товаром
     * @param {CatalogEntryType} entry - entry товара, который связывается с каталогом
     */

    async createEntry(catalogId: string, entry: CatalogEntryType): Promise<[CustomError | null, CatalogEntry | null]> {
        const url = `/catalogs/${catalogId}/entries`;
        const defaultValue = null;
        const errorPath = '[api:catalog:createEntry]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: entry });
    }

    /**
     * Обновление entry товара в каталоге
     * @param {String} catalogId - ID каталога для которого создается связь с товаром
     * @param {CatalogEntryType} entry - entry товара, который обновляем
     */

    async updateEntry(catalogId: string, entry: CatalogEntryType): Promise<[CustomError | null, CatalogEntry | null]> {
        const url = `/catalogs/${catalogId}/entries/${entry.id}`;
        const defaultValue = null;
        const errorPath = '[api:catalog:createEntry]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: entry });
    }

    /**
     * Удаление связи каталог-товар
     * @param {String} catalogId - ID каталога у которого удаляется связь с товаром
     * @param {Object} entryId - id entry товара в каталоге
     */

    async removeEntry(catalogId: string, entryId: string): Promise<[CustomError | null, null]> {
        const url = `/catalogs/${catalogId}/entries/${entryId}`;
        const defaultValue = null;
        const errorPath = '[api:catalog:removeEntry]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }
    /**
     * Получение каталогов
     * @param {SortingRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов каталогов
     */

    async getAll(params: SortingRequestParams = {}): Promise<[CustomError | null, Catalog[] | []]> {
        const url = `/catalogs`;
        const defaultValue = [] as [];
        const config = { params };
        const errorPath = '[api:catalog:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }
    /**
     * Получение отдельного каталога
     * @param {String} id - id каталога
     * @returns {Promise} - объект каталога
     */

    async getOne(id: string): Promise<[CustomError | null, Catalog | null]> {
        const url = `/catalogs/${id}`;
        const defaultValue = null;
        const errorPath = '[api:catalog:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }
    /**
     * Создание каталога
     * @param {Object} catalog - объект каталога
     * @returns {Promise} - кастомный объект, содержащий ответ сервера
     */

    async createOne(catalog: Catalog): Promise<[CustomError | null, Catalog | null]> {
        const url = `/catalogs`;
        const defaultValue = null;
        const errorPath = '[api:catalog:createOne]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: catalog });
    }
    /**
     * Обновление каталога
     * @param {Object} catalog - объект каталога
     * @returns {Promise} - кастомный объект, содержащий ответ сервера
     */

    async updateOne(catalog: Catalog): Promise<[CustomError | null, Catalog | null]> {
        const url = `/catalogs/${catalog.id}`;
        const defaultValue = null;
        const errorPath = '[api:catalog:updateOne]';
        /*       const redirect = catalog.redirect;
        if (redirect) {
            const [error] = await RedirectService.createOne(redirect);
            if (error) error.notify();
        }*/
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: catalog });
    }

    /**
     * Обновление нескольких каталогов
     * @param {Array} catalogs - массив объектов каталогов
     */
    async updateBatch(catalogs: Catalog[]): Promise<[CustomError | null, Product[] | []]> {
        const url = `/catalogs/batch`;
        const defaultValue = [] as [];
        const errorPath = '[api:catalog:updateBatch]';
        const config = { params: { ids: catalogs.map((catalog) => catalog.id) } };
        return await ApiClient.admin.put({
            url,
            defaultValue,
            errorPath,
            data: catalogs,
            config,
        });
    }

    /**
     * Удаление каталога
     * @param {Object} id - объект каталога
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url = `/catalogs/${id}`;
        const defaultValue = null;
        const errorPath = '[api:catalog:removeOne]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }

    /**
     * Получение товаров из конкретного каталога
     * @param {String} id - ID каталога из которого нужно получить товары
     * @param {Object} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} массив объектов товаров
     */

    async getProductsInCatalog(id: string, params = {}): Promise<[CustomError | null, Product[] | []]> {
        const url = `/catalogs/v2/${id}/products`;
        const defaultValue = [] as [];
        const errorPath = '[api:catalog:getProductsInCatalog]';
        const config = { params };
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Получение связей каталога
     * @param {String} id - ID каталога, связи которого нужно получить
     * @returns {Promise} массив объектов связей каталог-товар
     */

    async getAllEntries(id: string): Promise<[CustomError | null, CatalogEntryType[] | []]> {
        const url = `/catalogs/${id}/entries`;
        const defaultValue = [] as [];
        const errorPath = '[api:catalog:getAllEntries]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Получение изображений каталогов
     * @param {Array} catalogs - массив каталогов, изображения которых нужно получить
     * @returns {Promise} объект вида {catalogId: Image}
     */

    async getImages(
        catalogs: Catalog[]
    ): Promise<[CustomError | null, { [key: string]: Image } | { [key: string]: never }]> {
        const map = {} as { [key: string]: string };
        const imagesIds: string[] = [];
        catalogs.forEach((catalog) => {
            if (catalog.previewImageId) {
                map[catalog.previewImageId] = catalog.id;
                imagesIds.push(catalog.previewImageId);
            }
        });
        if (!imagesIds.length) {
            return [null, {}];
        }
        const imagesObj = {} as { [key: string]: Image };
        const [error, images] = await ImageService.getAll({ ids: imagesIds });
        images.forEach((image) => {
            const catalogId = map[image?.id];
            imagesObj[catalogId] = image;
        });
        return [error, imagesObj];
    }
}

export default new CatalogService();
