import OptionService from '@/services/OptionService';
import CatalogService from '@/services/CatalogService';
import PropertyService from '@/services/PropertyService';
//import RedirectService from '@/services/RedirectService';
import ApiClient from '@/api/apiClient';
import { formatForEditor } from '@/entities/product/ProductHelper';
import {
    Image,
    Product,
    Catalog,
    SortingRequestParams,
    OptionsToUpdate,
    CatalogEntryType,
    Option,
    Property,
    PropertiesToUpdate,
} from '@/services/services.types';
import CustomError from '@/entities/customError';
import CatalogEntry from '@/entities/catalog/CatalogEntry';

class ProductService {
    /**
     * Получение товара по ID
     * @param {String} id - ID товара
     * @returns {Promise} - объект товара
     */
    async getOne(id: string): Promise<[CustomError | null, Product | null]> {
        const url = `/products/${id}`;
        const defaultValue = null;
        const errorPath = '[api:product:getOne]';
        const [error, result] = await ApiClient.admin.get({ url, defaultValue, errorPath });
        // костыли для редактора
        const product = formatForEditor(result);
        return [error, product];
    }

    /**
     * Получение товаров
     * @param {SortingRequestParams} params - объект query параметров
     * @returns {Promise} - массив объектов товара
     */
    async getAll(params: SortingRequestParams = {}): Promise<[CustomError | null, Product[] | []]> {
        const url = `/products`;
        const defaultValue = [] as [];
        const errorPath = '[api:product:getAll]';
        const config = { params };
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Создание товара, опций и связей с каталогами
     * @param {Object} product - объект товара
     * @param {OptionsToUpdate} optionsToUpdate - объект опций, которые нужно создать или обновить
     * @param {Array} catalogIds - массив ID каталогов, для которых нужно создать связи с товаром
     * @param {Array} images - массив изображений
     */
    async createOne(
        product: Product,
        optionsToUpdate: OptionsToUpdate,
        catalogIds: string[] = [],
        images: Image[] = [],
        propertiesToUpdate: PropertiesToUpdate
    ): Promise<[CustomError | null, Product | null | Option[] | Property[] | []]> {
        const [optionsError, optionsResult] = await this.updateOptions(optionsToUpdate);
        //если ломается сохранение опций, то ломается и сохранение товара
        if (optionsError) return [optionsError, optionsResult];

        const [propertiesError, propertiesResult] = await this.updateProperties(propertiesToUpdate);
        if (propertiesError) return [propertiesError, propertiesResult];

        const formData: any = new FormData();
        formData.set('product', JSON.stringify(product));
        images.forEach((item) => {
            formData.append('files[]', item.file);
        });
        const url = `/products`;
        const defaultValue = null;
        const errorPath = '[api:product:createOne]';
        const response = await ApiClient.admin.post({ url, defaultValue, errorPath, data: formData });
        if (catalogIds.length) {
            await this.addToCatalogs(catalogIds, product.id);
        }
        return response;
    }

    /**
     * Обновление товара, опций и связей с каталогами
     * @param {Object} product - объект товара
     * @param {OptionsToUpdate} optionsToUpdate - массив опций, которые нужно создать или обновить
     * @param {Array} newCatalogIds - массив ID каталогов, для которых нужно создать связи с товаром
     * @param {Array} removedCatalogIds - массив ID каталогов, для которых нужно убрать связи с товаром
     */
    async updateOne(
        product: Product,
        optionsToUpdate: OptionsToUpdate,
        newCatalogIds: string[] = [],
        removedCatalogIds: string[] = [],
        propertiesToUpdate: PropertiesToUpdate
    ): Promise<[CustomError | null, Product | null | Option[] | Property[] | []]> {
        const [error, result] = await this.updateOptions(optionsToUpdate);
        //если ломается сохранение опций, то ломается и сохранение товара
        if (error) return [error, result];

        const [propertiesError, propertiesResult] = await this.updateProperties(propertiesToUpdate);
        if (propertiesError) return [propertiesError, propertiesResult];
        /*        const redirect = product.redirect;
        if (redirect) {
            //некритичная ошибка, так что просто вызываем notify и продолжаем сохранение
            const [error] = await RedirectService.createOne(redirect);
            if (error) error.notify();
        }*/
        if (newCatalogIds.length) {
            await this.addToCatalogs(newCatalogIds, product.id);
        }
        if (removedCatalogIds.length) {
            const promises: any = removedCatalogIds.map((catalogId) => {
                return CatalogService.getAllEntries(catalogId).then((data: any) => {
                    const [error, result] = data;
                    if (error) {
                        return [error, result];
                    }
                    const entry: CatalogEntryType | undefined = result.find(
                        (entry: CatalogEntryType) => entry.productId === product.id
                    );
                    if (!entry || !entry.id) {
                        Promise.resolve([null, null]);
                        return;
                    }
                    return CatalogService.removeEntry(catalogId, entry.id);
                });
            });
            const results = await Promise.all(promises);
            //некритичная ошибка, так что просто вызываем notify и продолжаем сохранение
            //стоит ли вызывать для каждого?
            results.forEach((item: [null | CustomError, null]) => {
                const [error] = item;
                if (error) {
                    error.notify();
                }
            });
        }
        const url = `/products/${product.id}`;
        const defaultValue = null;
        const errorPath = '[api:product:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: product });
    }

    async updateOptions(
        options: OptionsToUpdate = { newOptions: [], existingOptions: [] }
    ): Promise<[CustomError | null, Option[] | [] | null]> {
        const { newOptions, existingOptions } = options;
        if (newOptions.length && !existingOptions.length) {
            const [error, result] = await OptionService.createBatch(newOptions);
            if (error) return [error, result];
        } else if (existingOptions.length) {
            const [error, result] = await OptionService.updateBatch([...newOptions, ...existingOptions]);
            if (error) return [error, result];
        }
        return [null, null];
    }

    async updateProperties(
        properties: PropertiesToUpdate = { newProperties: [], existingProperties: [] }
    ): Promise<[CustomError | null, Property[] | [] | null]> {
        const { newProperties, existingProperties } = properties;
        if (newProperties.length && !existingProperties.length) {
            const [error, result] = await PropertyService.createBatch(newProperties);
            if (error) return [error, result];
        } else if (existingProperties.length) {
            const [error, result] = await PropertyService.updateBatch([...newProperties, ...existingProperties]);
            if (error) return [error, result];
        }
        return [null, null];
    }

    /**
     * Обновление нескольких товаров
     * @param {Array} products - массив объектов товаров
     */
    async updateBatch(products: Product[]): Promise<[CustomError | null, Product[] | []]> {
        const url = `/products/batch`;
        const defaultValue = [] as [];
        const errorPath = '[api:product:updateBatch]';
        const config = { params: { ids: products.map((product) => product.id) } };
        return await ApiClient.admin.put({
            url,
            defaultValue,
            errorPath,
            data: products,
            config,
        });
    }

    /**
     * Удаление товара
     * @param {String} id - ID товара
     */
    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url = `/products/${id}`;
        const defaultValue = null;
        const errorPath = '[api:product:removeOne]';
        return await ApiClient.admin.delete({
            url,
            defaultValue,
            errorPath,
        });
    }

    /**
     * Удаление всех товаров
     */
    async removeAll(): Promise<[CustomError | null, null]> {
        const url = `/products/clear`;
        const defaultValue = null;
        const errorPath = '[api:product:removeAll]';
        return await ApiClient.admin.delete({
            url,
            defaultValue,
            errorPath,
        });
    }

    /**
     * Получение каталогов товара
     * @param {String} id - ID товара
     * @returns {Promise} массив объектов каталогов товара
     */
    async getCatalogs(id: string): Promise<[CustomError | null, Catalog[] | []]> {
        const url = `/products/${id}/catalogs`;
        const defaultValue = [] as [];
        const errorPath = '[api:product:getCatalogs]';
        return await ApiClient.admin.get({
            url,
            defaultValue,
            errorPath,
        });
    }

    /**
     * Clone product
     * @param {String} id - ID
     * @returns {Promise}
     */
    async cloneProduct(id: string) {
        const url = `/products/clone/${id}`;
        const defaultValue = {};
        const errorPath = '[api:product:cloneProduct]';
        return await ApiClient.admin.get({
            url,
            defaultValue,
            errorPath,
        });
    }

    /**
     * Export all products in csv file
     */
    async export() {
        const url = `/products/export-full`;
        const defaultValue = {};
        const errorPath = '[api:product:export]';
        return await ApiClient.admin.get({
            url,
            defaultValue,
            errorPath,
        });
    }

    /**
     * Import products from Runshop .csv table
     * @param data - данные товаров
     */
    async import(data: any) {
        const formData = new FormData();
        formData.set('file', data.file);
        const url = `/products/v2/import`;
        const defaultValue = {};
        const errorPath = '[api:product:import]';
        return await ApiClient.admin.post({
            url,
            defaultValue,
            errorPath,
            data: formData,
        });
    }
    /**
     * Import products from Shopify .csv table
     * @param data - данные товаров
     */
    async importShopify(data: any) {
        const formData = new FormData();
        formData.set('file', data.file);
        const url = `/products/import-shopify`;
        const defaultValue = {};
        const errorPath = '[api:product:importShopify]';
        return await ApiClient.admin.post({
            url,
            defaultValue,
            errorPath,
            data: formData,
        });
    }
    async addToCatalogs(catalogIds: string[], productId: string) {
        const results = await Promise.all(
            catalogIds.map((catalogId) => CatalogService.createEntry(catalogId, new CatalogEntry({ productId })))
        );
        results.forEach((item) => {
            const [error] = item;
            if (error) {
                error.notify();
            }
        });
        return results;
    }
    /**
     * Получение товара по ID вариации
     * @param {String} id - ID вариации товара
     * @returns {Promise} - объект товара
     */
    async getOneVariation(id: string): Promise<[CustomError | null, Product | null]> {
        const url = `/products/variation/${id}`;
        const defaultValue = null;
        const errorPath = '[api:product:getOneVariation]';
        const [error, result] = await ApiClient.admin.get({ url, defaultValue, errorPath });
        // костыли для редактора
        const product = formatForEditor(result);
        return [error, product];
    }
}

export default new ProductService();
