import icons from '@/entities/icons';

export default (ctx) => ({
    title: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.property.fields.title',
            errors: [],
        },
    },

    usage: {
        component: 'Select',
        props: {
            labelTrans: 'entities.property.fields.usage',
            items: [
                { text: 'entities.property.usage.catalogs', value: 'catalogs' },
                { text: 'entities.property.usage.none', value: 'none' },
                { text: 'entities.property.usage.global', value: 'global' },
            ],
            translate: true,
            errors: [],
        },
        events: {
            input: (value) => {
                ctx.global = value === 'global';
            },
        },
    },
    catalogIds: {
        component: 'SelectMultiple',
        props: {
            labelTrans: 'entities.property.fields.catalogIds',
            class: '-two-thirds-width',
            autocomplete: true,
            multiple: true,
            onlyModel: true,
            returnObject: false,
            rules: ['noEmpty'],
            errors: [],
        },
    },

    useInFilter: {
        component: 'Select',
        props: {
            label: 'entities.property.fields.useInFilter',
            class: '-half-width',
            translate: true,
            items: [
                { text: 'entities.property.useInFilter.use', value: 'useInFilter' },
                { text: 'entities.property.useInFilter.doNotUse', value: 'doNotUseInFilter' },
            ],
            errors: [],
        },
    },

    position: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.property.fields.position',
            class: '-half-width',
            type: 'number',
            errors: [],
        },
    },

    values: {
        component: 'ComboboxMultiple',
        props: {
            labelTrans: 'entities.property.fields.values',
            draggable: true,
            editable: true,
            hasDropdown: false,
            errors: [],
        },
        events: {
            input: ctx.onChangePropertyValue,
            'drag-end': function(values) {
                this.values = values.map((item, index) => {
                    item.position = index;
                    return item;
                });
            },
        },
    },

    unit: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.property.fields.unit',
            class: '-half-width',
            errors: [],
        },
    },

    icon: {
        component: 'InputIcon',
        props: {
            labelTrans: 'entities.property.fields.icon',
            class: '-half-width',
            errors: [],
            items: [...icons],
        },
    },
});
