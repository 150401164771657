export default {
    columns: {
        amount: 'Сумма',
        clientInfo: 'О клиенте',
        color: 'Цвет',
        conditions: 'Условия',
        createdAt: 'Создано',
        date: 'Дата',
        dateEnd: 'Дата завершения',
        dateNextBilling: 'Дата следующей оплаты',
        dateStart: 'Дата начала',
        default: 'По умолчанию',
        delivery: 'Доставка',
        discount: 'Скидка',
        domain: 'Домен',
        email: 'Email',
        enabled: 'Активно',
        fromUrl: 'С URL',
        fullPrice: 'Цена',
        hidden: 'Скрыто',
        id: 'ID',
        image: 'Картинка',
        item: 'Элемент',
        manager: 'Менеджер',
        name: 'Имя',
        options: 'Опции',
        order: 'Заказ',
        payment: 'Оплата',
        position: 'Позиция',
        price: 'Цена',
        priceAfterDiscount: 'Цена со скидкой',
        primary: 'Основной',
        product: 'Товар',
        products: 'Товары',
        published: 'Статус',
        quantity: 'Количество',
        rateName: 'Название тарифа',
        registeredDate: 'Дата регистрации',
        reviews: 'Отзывы',
        role: 'Роль',
        shippingSpeed: 'Срок доставки',
        sku: 'SKU',
        status: 'Статус',
        stock: 'Наличие',
        toObject: 'На объект',
        subjectType: 'Тип объекта',
        text: 'Текст',
        title: 'Название',
        toUrl: 'На URL',
        total: 'Всего',
        type: 'Тип',
        unit: 'Единицы измерения',
        values: 'Значения',
        view: 'Вид',
        number: 'Номер',
        phone: 'Телефон',
        comment: 'Комментарий',
        state: 'Статус',
        messageFromClient: 'Сообщение от клиента',
        size: 'Размер (см)',
        weight: 'Вес (кг)',
        publicName: 'Публичное имя',
        serviceName: 'Имя',
        deleted: 'Удалено',
        photoId: 'Фото',
        bio: 'О себе',
        articles: 'Статьи',
        jobTitle: 'Должность',
        tagIds: 'Теги',
        annotation: 'Тизер поста',
        'author/publisher': 'Автор / Опубликовавший',
        publishedAt: 'Опубликовано',
        updatedAt: 'Изменено',
        comments: 'Комментарии',
        articleIds: 'Статьи',
        articleId: 'Статья',
        categoryId: 'Категория',
        articlesCreatedIds: 'Написанные статьи',
        catalogs: 'Каталоги',
        icon: 'Иконка',
        usage: 'Использование',
        useInFilter: 'Использовать в фильтрах',
        gtin: 'gtin',
    },

    addButton: {
        order: 'Добавить Заказ',
        page: 'Добавить Страницу',
        catalog: 'Добавить Каталог',
        option: 'Добавить Опцию',
        review: 'Добавить Отзыв',
        product: 'Добавить Товар',
        label: 'Добавить Лейбл',
        location: 'Добавить Локацию',
        shippingZone: 'Добавить зону доставки',
        rate: 'Добавить тариф',
        user: 'Добавить пользователя',
        domain: 'Добавить домен',
        orderState: 'Добавить статус заказа',
        tag: 'Добавить тег',
        property: 'Добавить характеристику',
    },

    editButton: {
        order: 'Редактировать заказ',
        page: 'Редактировать страницу',
        catalog: 'Редактировать каталог',
        option: 'Редактировать опцию',
        review: 'Редактировать отзыв',
        product: 'Редактировать товар',
        label: 'Редактировать лейбл',
        shippingZone: 'Редактировать зону доставки',
        rate: 'Редактировать тариф',
        user: 'Редактировать пользователя',
        domain: 'Изменить тип домена',
        lead: 'Редактировать заявку',
        orderState: 'Редактировать статус заказа',
        tag: 'Редактировать тег',
        property: 'Редактировать характеристику',
    },

    deleteButton: {
        order: 'Удалить заказ',
        page: 'Удалить страницу',
        catalog: 'Удалить каталог',
        option: 'Удалить опцию',
        review: 'Удалить отзыв',
        product: 'Удалить товар',
        label: 'Удалить лейбл',
        lead: 'Удалить заявку',
    },
    perPage: 'показать по {count}',
    actions: {
        editInBuilder: 'Изменить в Билдере',
        deleteSelected: 'Удалить выбранные',
        showSelected: 'Показывать вабранные',
        hideSelected: 'Скрыть выбранные',
        disableSelected: 'Выключить выбранные',
        enableSelected: 'Включить выбранные',
        importProducts: 'Импортировать товары',
        viewAll: 'Посмотреть все',
        deleteAll: 'Удалить все',
    },

    text: {
        from: 'от',
        priceOnRequest: 'Цена по запросу',
    },
    noItems: 'Ничего не найдено',
    loadingItems: 'Идёт загрузка',
};
