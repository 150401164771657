import i18n from '@/plugins/i18n';
export default (ctx) => ({
    title: {
        type: 'field',
        component: 'InputText',
        props: {
            labelTrans: 'entities.article.fields.title',
            class: '-half-width',
            rules: ['noEmpty'],
            errors: [],
        },
    },
    enabled: {
        type: 'field',
        component: 'input-switch',
        props: {
            noLabel: true,
            text: 'entities.article.fields.enabled',
            class: 'sidebar__switch',
            size: 'small',
            errors: [],
        },
    },
    hidden: {
        type: 'field',
        component: 'input-switch',
        props: {
            noLabel: true,
            text: 'entities.article.fields.hidden',
            class: 'sidebar__switch',
            size: 'small',
            errors: [],
        },
    },
    noIndex: {
        type: 'field',
        component: 'input-switch',
        props: {
            noLabel: true,
            text: 'entities.article.fields.noIndex',
            class: 'sidebar__switch',
            size: 'small',
            errors: [],
        },
    },
    heading: {
        type: 'field',
        component: 'InputText',
        props: {
            labelTrans: 'entities.article.fields.heading',
            class: '-half-width',
            rules: ['noEmpty'],
            errors: [],
        },
        events: {
            input: ctx.changeHeading,
        },
    },
    previewImageId: {
        component: 'image-field',
        props: {
            labelTrans: 'entities.page.fields.previewImageId',
            imageType: 'article',
            note: 'JPG, PNG. Recommended size: 1200х630px. No more than 500kb',
            type: 'content',
        },
    },
    authorId: {
        type: 'field',
        component: 'Select',
        props: {
            labelTrans: 'entities.article.fields.authorId',
            class: '-half-width',
            errors: [],
            canBeEmpty: true,
            'item-text': 'name',
            'item-value': 'id',
        },
    },
    publisherId: {
        type: 'field',
        component: 'Select',
        props: {
            labelTrans: 'entities.article.fields.publisherId',
            class: '-half-width',
            errors: [],
            'item-text': 'name',
            'item-value': 'id',
        },
    },
    categoryId: {
        type: 'field',
        component: 'Select',
        props: {
            labelTrans: 'entities.article.fields.categoryId',
            class: '-half-width',
            rules: ['noEmpty'],
            errors: [],
            'item-text': 'title',
            'item-value': 'id',
        },
    },
    tagIds: {
        type: 'field',
        component: 'select-multiple',
        props: {
            label: 'entities.article.fields.tagIds',
            class: '-half-width',
            items: [],
            returnObject: false,
            'item-text': (tag) => tag.name,
            'item-value': (tag) => tag.id,
        },
    },

    annotation: {
        type: 'field',
        component: 'Textarea',
        props: {
            labelTrans: 'entities.article.fields.annotation',
            errors: [],
            validateFunction(value) {
                return value && value.length > 200 ? [i18n.t('validator.errors.maxLength', { length: 200 })] : [];
            },
        },

        events: {},
    },
    slug: {
        label: 'URL',
        class: '-half-width',
        component: 'input-url',
        props: {
            label: 'entities.article.fields.slug',
            rules: ['noEmpty'],
        },
        events: {
            checkRedirect: ctx.createRedirectOnSave,
        },
    },
    metaTitle: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.article.fields.metaTitle',
        },
    },
    publishedAt: {
        component: 'date-picker',
        props: {
            class: '-half-width',
            labelTrans: 'entities.article.fields.publishedAt',
        },
    },
    metaDescription: {
        component: 'Textarea',
        props: {
            labelTrans: 'entities.article.fields.metaDescription',
        },
    },
});
