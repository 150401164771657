export default {
    continents: {
        africa: 'Африка',
        europe: 'Европа',
        asia: 'Азия',
        centralAmerica: 'Центральная Америка',
        southAmerica: 'Южная Америка',
        northAmerica: 'Северная Америка',
        oceania: 'Океания',
        other: 'Другое',
    },
    RU: {
        title: 'Россия',
        zones: {
            ALT: 'Алтайский край',
            AL: 'Алтай',
            AMU: 'Амур',
            ARK: 'Архангельская область',
            AST: 'Астраханская область',
            BEL: 'Белгородская область',
            BRY: 'Брянская область',
            CE: 'Чеченская республика',
            CHE: 'Челябинская область',
            CHU: 'Чукотский АО',
            CU: 'Чувашия',
            IRK: 'Иркутская область',
            IVA: 'Ивановская область',
            YEV: 'Еврейская АО',
            KB: 'Кабардино-Балкарская Республика',
            KGD: 'Калининградская область',
            KLU: 'Калужская область',
            KAM: 'Камчатский край',
            KC: 'Карачаево-Черкесская Республика',
            KEM: 'Кемеровская область',
            KHA: 'Хабаровский край',
            KHM: 'Ханты-Мансийский АО',
            KIR: 'Кировская область',
            KO: 'Республика Коми',
            KOS: 'Костромская область',
            KDA: 'Краснодарский край',
            KYA: 'Красноярский край',
            KGN: 'Курганская область',
            KRS: 'Курская область',
            LEN: 'Ленинградская область',
            LIP: 'Липецкая область',
            MAG: 'Магаданская область',
            ME: 'Республика Марий Эл',
            MOW: 'Москва',
            MOS: 'Московская область',
            MUR: 'Мурманская область',
            NIZ: 'Нижегородская область',
            NGR: 'Новгородская область',
            NVS: 'Новосибирская область',
            OMS: 'Омская область',
            ORE: 'Оренбургская область',
            ORL: 'Орловская область',
            PNZ: 'Пензенская область',
            PER: 'Пермский край',
            PRI: 'Приморский край',
            PSK: 'Псковская область',
            AD: 'Республика Адыгея',
            BA: 'Республика Башкортостан',
            BU: 'Республика Бурятия',
            DA: 'Республика Дагестан',
            IN: 'Республика Ингушетия',
            KL: 'Республика Калмыкия',
            KR: 'Республика Карелия',
            KK: 'Республика Хакасия',
            MO: 'Республика Мордовия',
            SE: 'Республика Северная Осетия — Алания',
            TA: 'Республика Татарстан',
            ROS: 'Ростовская область',
            RYA: 'Рязанская область',
            SPE: 'Санкт-Петербург',
            SA: 'Республика Саха (Якутия)',
            SAK: 'Сахалинская область',
            SAM: 'Самарская область',
            SAR: 'Саратовская область',
            SMO: 'Смоленская область',
            STA: 'Ставропольский край',
            SVE: 'Свердловская область',
            TAM: 'Тамбовская область',
            TOM: 'Томская область',
            TUL: 'Тульская область',
            TVE: 'Тверская область',
            TYU: 'Тюменская область',
            TY: 'Республика Тыва',
            UD: 'Удмуртская Республика',
            ULY: 'Ульяновская область',
            VLA: 'Владимирская область',
            VGG: 'Волгоградская область',
            VLG: 'Вологодская область',
            VOR: 'Воронежская область',
            YAN: 'Ямало-Ненецкий АО',
            YAR: 'Ярославская область',
            ZAB: 'Забайкальский край',
        },
    },
    AU: 'Австралия',
    AC: 'Остров Вознесения',
    XK: 'Республика Косово',
    TA: 'Тристан-да-Кунья',
    AT: 'Австрия',
    AZ: 'Азербайджан',
    AL: 'Албания',
    DZ: 'Алжир',
    AI: 'Ангилья',
    AO: 'Ангола',
    AD: 'Андорра',
    AG: 'Антигуа и Барбуда',
    AR: 'Аргентина',
    AM: 'Армения',
    AW: 'Аруба',
    AF: 'Афганистан',
    BS: 'Багамы',
    BD: 'Бангладеш',
    BB: 'Барбадос',
    BH: 'Бахрейн',
    BY: 'Беларусь',
    BZ: 'Белиз',
    BE: 'Бельгия',
    BJ: 'Бенин',
    BM: 'Бермуды',
    BG: 'Болгария',
    BO: 'Боливия, Многонациональное Государство',
    BQ: 'Бонайре, Саба и Синт-Эстатиус',
    BA: 'Босния и Герцеговина',
    BW: 'Ботсвана',
    BR: 'Бразилия',
    IO: 'Британская территория в Индийском океане',
    BN: 'Бруней-Даруссалам',
    BF: 'Буркина-Фасо',
    BI: 'Бурунди',
    BT: 'Бутан',
    VU: 'Вануату',
    HU: 'Венгрия',
    VE: 'Венесуэла Боливарианская Республика',
    VG: 'Виргинские острова, Британские',
    VN: 'Вьетнам',
    GA: 'Габон',
    HT: 'Гаити',
    GY: 'Гайана',
    GM: 'Гамбия',
    GH: 'Гана',
    GP: 'Гваделупа',
    GT: 'Гватемала',
    GN: 'Гвинея',
    GW: 'Гвинея-Бисау',
    DE: 'Германия',
    GG: 'Гернси',
    GI: 'Гибралтар',
    HN: 'Гондурас',
    HK: 'Гонконг',
    GD: 'Гренада',
    GL: 'Гренландия',
    GR: 'Греция',
    GE: 'Грузия',
    DK: 'Дания',
    JE: 'Джерси',
    DJ: 'Джибути',
    DM: 'Доминика',
    DO: 'Доминиканская Республика',
    EG: 'Египет',
    ZM: 'Замбия',
    EH: 'Западная Сахара',
    ZW: 'Зимбабве',
    IL: 'Израиль',
    IN: 'Индия',
    ID: 'Индонезия',
    JO: 'Иордания',
    IQ: 'Ирак',
    IE: 'Ирландия',
    IS: 'Исландия',
    ES: 'Испания',
    IT: 'Италия',
    YE: 'Йемен',
    CV: 'Кабо-Верде',
    KZ: 'Казахстан',
    KH: 'Камбоджа',
    CM: 'Камерун',
    CA: 'Канада',
    QA: 'Катар',
    KE: 'Кения',
    CY: 'Кипр',
    KG: 'Киргизия',
    KI: 'Кирибати',
    CN: 'Китай',
    CC: 'Кокосовые (Килинг) острова',
    CO: 'Колумбия',
    KM: 'Коморы',
    CG: 'Конго',
    CD: 'Конго, Демократическая Республика',
    KR: 'Корея, Республика',
    CR: 'Коста-Рика',
    CI: "Кот д'Ивуар",
    KW: 'Кувейт',
    CW: 'Кюрасао',
    LA: 'Лаос',
    LV: 'Латвия',
    LS: 'Лесото',
    LB: 'Ливан',
    LY: 'Ливийская Арабская Джамахирия',
    LR: 'Либерия',
    LI: 'Лихтенштейн',
    LT: 'Литва',
    LU: 'Люксембург',
    MU: 'Маврикий',
    MR: 'Мавритания',
    MG: 'Мадагаскар',
    YT: 'Майотта',
    MO: 'Макао',
    MW: 'Малави',
    MY: 'Малайзия',
    ML: 'Мали',
    UM: 'Малые Тихоокеанские отдаленные острова Соединенных Штатов',
    MV: 'Мальдивы',
    MT: 'Мальта',
    MA: 'Марокко',
    MQ: 'Мартиника',
    MX: 'Мексика',
    MZ: 'Мозамбик',
    MD: 'Молдова, Республика',
    MC: 'Монако',
    MN: 'Монголия',
    MS: 'Монтсеррат',
    MM: 'Мьянма',
    NA: 'Намибия',
    NR: 'Науру',
    NP: 'Непал',
    NE: 'Нигер',
    NG: 'Нигерия',
    NL: 'Нидерланды',
    NI: 'Никарагуа',
    NU: 'Ниуэ',
    NZ: 'Новая Зеландия',
    NC: 'Новая Каледония',
    NO: 'Норвегия',
    AE: 'Объединенные Арабские Эмираты',
    OM: 'Оман',
    IM: 'Остров Мэн',
    NF: 'Остров Норфолк',
    CX: 'Остров Рождества',
    KY: 'Острова Кайман',
    CK: 'Острова Кука',
    TC: 'Острова Теркс и Кайкос',
    PK: 'Пакистан',
    PS: 'Палестинская территория, оккупированная',
    PA: 'Панама',
    VA: 'Папский Престол (Государство — город Ватикан)',
    PG: 'Папуа-Новая Гвинея',
    PY: 'Парагвай',
    PE: 'Перу',
    PN: 'Питкерн',
    PL: 'Польша',
    PT: 'Португалия',
    MK: 'Республика Македония',
    RE: 'Реюньон',
    RW: 'Руанда',
    RO: 'Румыния',
    WS: 'Самоа',
    SM: 'Сан-Марино',
    ST: 'Сан-Томе и Принсипи',
    SA: 'Саудовская Аравия',
    SH: 'Святая Елена, Остров вознесения, Тристан-да-Кунья',
    BL: 'Сен-Бартельми',
    MF: 'Сен-Мартен',
    SN: 'Сенегал',
    VC: 'Сент-Винсент и Гренадины',
    KN: 'Сент-Китс и Невис',
    LC: 'Сент-Люсия',
    PM: 'Сент-Пьер и Микелон',
    RS: 'Сербия',
    SC: 'Сейшелы',
    SG: 'Сингапур',
    SX: 'Синт-Мартен',
    SK: 'Словакия',
    SI: 'Словения',
    GB: 'Соединенное Королевство',
    US: 'Соединенные Штаты',
    SB: 'Соломоновы острова',
    SO: 'Сомали',
    SD: 'Судан',
    SR: 'Суринам',
    SL: 'Сьерра-Леоне',
    TJ: 'Таджикистан',
    TH: 'Таиланд',
    TW: 'Тайвань (Китай)',
    TZ: 'Танзания, Объединенная Республика',
    TL: 'Тимор-Лесте',
    TG: 'Того',
    TK: 'Токелау',
    TO: 'Тонга',
    TT: 'Тринидад и Тобаго',
    TV: 'Тувалу',
    TN: 'Тунис',
    TM: 'Туркмения',
    TR: 'Турция',
    UG: 'Уганда',
    UZ: 'Узбекистан',
    UA: 'Украина',
    WF: 'Уоллис и Футуна',
    UY: 'Уругвай',
    FO: 'Фарерские острова',
    FJ: 'Фиджи',
    PH: 'Филиппины',
    FI: 'Финляндия',
    FK: 'Фолклендские острова (Мальвинские)',
    FR: 'Франция',
    GF: 'Французская Гвиана',
    PF: 'Французская Полинезия',
    TF: 'Французские Южные территории',
    HR: 'Хорватия',
    CF: 'Центрально-Африканская Республика',
    TD: 'Чад',
    ME: 'Черногория',
    CZ: 'Чешская Республика',
    CL: 'Чили',
    CH: 'Швейцария',
    SE: 'Швеция',
    SJ: 'Шпицберген и Ян Майен',
    LK: 'Шри-Ланка',
    EC: 'Эквадор',
    GQ: 'Экваториальная Гвинея',
    AX: 'Эландские острова',
    SV: 'Эль-Сальвадор',
    ER: 'Эритрея',
    SZ: 'Эсватини',
    EE: 'Эстония',
    ET: 'Эфиопия',
    ZA: 'Южная Африка',
    GS: 'Южная Джорджия и Южные Сандвичевы острова',
    SS: 'Южный Судан',
    JM: 'Ямайка',
    JP: 'Япония',
};
