import axios from 'axios';
import EventEmitter from '@/helpers/eventEmitter';

export default class CustomError {
    message = 'Unknown Error';
    errorPath: string;
    detail: any | null;
    constructor(error: any, errorPath = '', data: { [key: string]: unknown } | null = null) {
        if (error instanceof Error) this.message = error.message;
        else if (axios.isAxiosError(error)) this.message = error.message;
        // else if (error.message && error.statusCode) this.message = `Error ${error.statusCode} ${error.message}`;
        else if (error.message && error.statusCode) this.message = `Error ${error.statusCode} ${error.message}`;
        this.detail = data || null;
        this.errorPath = errorPath;
    }

    generateMessageConsole(): string {
        let errorList = '';
        const children = this.detail?.errors?.children;
        if (children) {
            Object.keys(children).forEach((key) => {
                if (children[key].errors) errorList += `\n - ${key}: ${children[key].errors[0]}`;
            });
        }
        return `${this.message}
detail: ${errorList}
path: ${this.errorPath}`;
    }

    generateMessageNoty(): string {
        let errorList = '';
        const children = this.detail?.errors?.children;
        if (children) {
            Object.keys(children).forEach((key) => {
                if (children[key].errors) errorList += `\n${children[key].errors[0]}`;
            });
        }
        return `${this.message} - ${errorList}`;
    }

    /** For non-critical errors */
    notify() {
        EventEmitter.trigger('show-noty', {
            type: 'error',
            text: this.generateMessageNoty(),
        });
        console.log(this.generateMessageConsole());
    }

    /** For critical errors */
    alert() {
        EventEmitter.trigger('show-noty', {
            type: 'error',
            text: this.generateMessageNoty(),
        });
        console.error(this.generateMessageConsole());
    }
}
