import { uuid } from 'vue-uuid';
import { cloneDeep } from 'lodash';
import convertOptionsToValue from '@/helpers/convertOptionsToValue';

/** Класс экземпляра элемента секции */
export default class SectionItem {
    /**
     * Create Section Item
     * @param {Object} data - section data
     * @param {string} data.id - id uuid.v4
     * @param {string} data.type - section type (can check all type in sampleSections)
     * @param {string} data.position - The employee's department.
     * @param {string} data.options - options, like a title or background
     * @param {Object} optionsShema - section item shema
     */
    constructor({ type = '', id = uuid.v4(), options = {}, position = 0 } = {}, childrenShema) {
        this.id = id ? id : uuid.v4();
        this.type = type ? type : childrenShema.type;
        this.options = this.assignOptions(cloneDeep(childrenShema.options), options);
        this.position = position;
        this.hasErrors = this.checkError();
    }

    /**
     * @param {Object} sampleOption - опции из дефолтной итема секции данного типа
     * @param {Object} newOption - опции пришедшие при инициализации итема секции
     * @returns {Object} - объединенные опции
     */
    assignOptions(sampleOption, newOption) {
        const newData = cloneDeep(newOption);
        convertOptionsToValue(newData);
        Object.keys(sampleOption).forEach((group) => {
            // для одноуровневых свойств
            if (sampleOption[group].value !== undefined && newData[group] !== undefined) {
                sampleOption[group].value = newData[group];
            }
            // для двухуровеневых свойств
            if (typeof sampleOption[group] === 'object' && sampleOption[group] !== null) {
                const props = Object.keys(sampleOption[group]);
                props.forEach((prop) => {
                    if (
                        sampleOption[group][prop]?.value !== undefined &&
                        newData[group] &&
                        newData[group][prop] !== undefined
                    ) {
                        sampleOption[group][prop].value = newData[group][prop];
                    }
                });
            }
        });
        return sampleOption;
    }

    get data() {
        return {
            id: this.id,
            type: this.type,
            options: convertOptionsToValue(cloneDeep(this.options)),
            position: this.position,
        };
    }

    checkError() {
        let result = false;
        Object.keys(this.options).forEach((group) => {
            if (this.options[group].component) {
                if (
                    this.options[group].props?.errors &&
                    Array.isArray(this.options[group].props.errors) &&
                    this.options[group].props.errors.length > 0
                )
                    result = true;
            } else {
                Object.keys(this.options[group]).forEach((optionKey) => {
                    const option = this.options[group][optionKey];
                    if (option.props?.errors && Array.isArray(option.props.errors) && option.props.errors.length > 0)
                        result = true;
                });
            }
        });
        return result;
    }
}
