import apiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { RequestParams, User } from '@/services/services.types';

class UserService {
    /**
     * Получение текущего пользователя
     * @returns {Promise} - объект пользователя
     */
    async getCurrent(): Promise<[CustomError | null, User | null]> {
        const url = `/user`;
        const defaultValue = null;
        const errorPath = '[api:user:getCurrent]';
        return await apiClient.account.get({ url, defaultValue, errorPath });
    }

    /**
     * Получение конкретного пользователя
     * @param {String} id - id пользователя
     * @returns {Promise} - объект пользователя
     */
    async getOne(id: string): Promise<[CustomError | null, User | null]> {
        const url = `/users/${id}`;
        const defaultValue = null;
        const errorPath = '[api:user:getOne]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Получение пользователей
     * @param {RequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов пользователей
     */
    async getAll(params: RequestParams = {}): Promise<[CustomError | null, User[] | []]> {
        const url = `/users`;
        const defaultValue = [] as [];
        const errorPath = '[api:user:getAll]';
        const config = { params };
        return await apiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Создание пользователя
     * @param {User} user - объект пользователя
     * @returns {Promise} - созданный объект пользователя
     */
    async createOne(user: User): Promise<[CustomError | null, User | null]> {
        const url = `/users`;
        const defaultValue = null;
        const errorPath = '[api:user:createOne]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: user });
    }

    /**
     * Обновление канкретного пользователя
     * @param {User} user - объект пользователя
     * @returns {Promise} - обновленный объект пользователя
     */
    async updateOne(user: User): Promise<[CustomError | null, User | null]> {
        const url = `/users/${user.id}`;
        const defaultValue = null;
        const errorPath = '[api:user:updateOne]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: user });
    }

    /**
     * Удаление пользователя
     * @param {String} id - id пользователя
     * @returns {Promise} - null
     */
    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url = `/users/${id}`;
        const defaultValue = null;
        const errorPath = '[api:user:removeOne]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }

    /**
     * Получение доступных инстансов
     * @returns {Promise} - массив объектов инстансов
     */
    async getInctancesAll(): Promise<[CustomError | null, any[] | []]> {
        const url = `/instances/all`;
        const defaultValue = [] as [];
        const errorPath = '[api:user:getInctancesAll]';
        return await apiClient.account.get({ url, defaultValue, errorPath });
    }
}

export default new UserService();
