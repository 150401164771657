import { uuid } from 'vue-uuid';
import { validateNoEmpty } from '@/helpers/validator';
import { fieldsErrorsInit } from '@/helpers/fieldsBuilder';
import { validateSizeInput } from '@/entities/product/ProductHelper';

function validateLessThan(val, targetVal) {
    if (!val || val === '0') return { isValid: true };
    let isValid = true;
    if (val.endsWith('%')) {
        const percentage = val.slice(0, val.length - 1);
        if (Number(percentage) > 99.99) {
            isValid = false;
        }
    } else {
        isValid = Number(val) < Number(targetVal);
    }
    return {
        isValid,
    };
}

function validateDiscount(val) {
    if (!val) return { isValid: true };
    const regEx = /(\d+\.\d{1,2}%)|(^\d+%?$)/;
    const isValid = regEx.test(val);
    return {
        isValid,
    };
}

/**
 * Класс экземпляра варианта товара
 */
export default class ProductVariation {
    fields = fieldsErrorsInit({
        default: {
            props: {
                prefix: 'pc',
            },
        },
        title: {
            props: {},
        },
        //TODO: потом будет только image, это убрать
        imageId: {
            props: {},
        },
        sku: {
            props: {},
        },
        stock: {
            props: {},
        },
        fullPrice: {
            props: {
                noLabel: true,
                rules: {
                    noEmpty: { onlyDefault: true },
                    related: ['discount'],
                },
            },
        },
        discount: {
            props: {
                rules: {
                    discount: true,
                    lessThan: { target: 'fullPrice' },
                },
            },
        },
        discountPrice: {
            props: {},
        },
        size: {
            props: {
                rules: {
                    size: true,
                },
            },
        },
        weight: {
            props: {},
        },
        gtin: {
            props: {},
        },
    });

    /**
     *
     * @param {Object}  data - объект данных варианта
     */
    constructor({
        id = uuid.v4(),
        default: defaultVariant = false,
        title = '',
        image = null,
        //TODO: потом будет только image, это убрать
        imageId = '',
        sku = null,
        productOptionValueIds = [],
        discount = '0',
        fullPrice = 0,
        stock = '',
        discountPrice = 0,
        weight = null,
        height = null,
        width = null,
        length = null,
        gtin = null,
    } = {}) {
        this.id = id;
        this.default = defaultVariant;
        this.title = title;
        this.image = image;
        //TODO: потом будет только image, это убрать
        this.imageId = imageId;
        this.sku = sku;
        this.productOptionValueIds = [...productOptionValueIds];
        this.fullPrice = fullPrice;
        this.discount = discount;
        this.discountPrice = discountPrice;
        this.stock = stock;
        this.width = width;
        this.height = height;
        this.length = length;
        this.weight = weight;
        this.gtin = gtin;
    }

    get data() {
        return {
            id: this.id,
            default: this.default,
            title: this.title,
            //  image: this.image,
            //TODO: потом будет только image, это убрать
            imageId: this.imageId,
            sku: this.sku,
            productOptionValueIds: this.productOptionValueIds,
            fullPrice: this.fullPrice,
            discount: this.discount,
            discountPrice: this.getFinalPrice(),
            stock: this.stock,
            width: this.width,
            height: this.height,
            length: this.length,
            weight: this.weight,
            gtin: this.gtin,
        };
    }

    getFinalPrice() {
        const discount = this.discount;
        const price = this.fullPrice;
        if (!discount) {
            return price;
        }
        if (discount.endsWith('%')) {
            return Math.ceil(+price - (+price / 100) * discount.slice(0, discount.length - 1));
        } else if (discount.includes('.')) {
            return null;
        } else {
            return +price - +discount;
        }
    }

    validateField(item, value) {
        const rules = item.props?.rules;

        if (!rules || rules.length === 0) return [];
        const errors = [];

        for (const [rule, options] of Object.entries(item.props.rules)) {
            if (!this.default && options.onlyDefault) continue;
            switch (rule) {
                case 'noEmpty': {
                    if (!validateNoEmpty(value)) errors.push('validator.errors.empty');
                    break;
                }
                case 'lessThan': {
                    if (!validateLessThan(value, this[options.target]).isValid)
                        errors.push(`validator.errors.lessThan`);
                    break;
                }
                case 'related': {
                    options.forEach((item) => {
                        this.validateRelated(this.fields[item], this[item]);
                    });
                    break;
                }
                case 'discount': {
                    if (!validateDiscount(value).isValid) errors.push('validator.errors.discount');
                    break;
                }
                case 'size': {
                    errors.push(
                        ...validateSizeInput({
                            value,
                        })
                    );
                }
            }
        }
        return errors;
    }

    validateRelated(item, value) {
        item.props.errors = this.validateField(item, value);
    }

    validateEntity() {
        let result = true;
        for (const key in this.fields) {
            const errors = this.validateField(this.fields[key], this[key]);
            if (errors.length !== 0) result = false;
            this.fields[key].props.errors = errors;
        }

        return result;
    }

    get size() {
        return { length: this.length, height: this.height, width: this.width };
    }
}
