import apiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { ProductReview, ReviewRequestParams } from '@/services/services.types';

class ProductReviewService {
    /**
     * Получение конкретного отзыва
     * @param {String} id - id отзыва
     * @returns {Promise} - объект отзыва
     */

    async getOne(id: string): Promise<[CustomError | null, ProductReview | null]> {
        const url = `/product-reviews/${id}`;
        const defaultValue = null;
        const errorPath = '[api:productReview:getOne]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание отзыва
     * @param {ProductReview} review - объект отзыва
     * @returns {Promise} - созданный объект отзыва
     */

    async createOne(review: ProductReview): Promise<[CustomError | null, ProductReview | null]> {
        const url = `/product-reviews`;
        const defaultValue = null;
        const errorPath = '[api:productReview:createOne]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: review });
    }

    /**
     * Получение отзывов
     * @param {ReviewRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} массив отзывов
     */

    async getAll(params: ReviewRequestParams = {}): Promise<[CustomError | null, ProductReview[] | []]> {
        const url = `/product-reviews`;
        const defaultValue = [] as [];
        const errorPath = '[api:productReview:getAll]';
        const config = { params };
        return await apiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Обновление отзыва
     * @param {ProductReview} review - объект отзыва
     * @returns {Promise} - обновлённый объект отзыва
     */

    async updateOne(review: ProductReview): Promise<[CustomError | null, ProductReview | null]> {
        const url = `/product-reviews/${review.id}`;
        const defaultValue = null;
        const errorPath = '[api:productReview:updateOne]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: review });
    }

    /**
     * Удаление отзыва
     * @param {String} id - id отзыва
     * @returns {Promise} null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url = `/product-reviews/${id}`;
        const defaultValue = null;
        const errorPath = '[api:productReview:removeOne]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }

    /**
     * Получение количества отзывов
     * @param {Object} params - параметры запроса (product, enabled, etc.)
     * @returns {Promise} количество отзывов
     */

    async getCount(params = {}): Promise<[CustomError | null, string | null]> {
        const url = `/product-reviews/count`;
        const defaultValue = null;
        const errorPath = '[api:productReview:getCount]';
        const config = { params };
        return await apiClient.admin.get({ url, defaultValue, errorPath, config });
    }
}

export default new ProductReviewService();
