import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Image, SortingRequestParams } from '@/services/services.types';
import Article from '@/entities/blog/Article';
import Comment from '@/entities/blog/Comment';
import ImageService from '@/services/ImageService';

class ArticleService {
    /*    /!**
     * Обновление entry товара в каталоге
     * @param {String} catalogId - ID каталога для которого создается связь с товаром
     * @param {CatalogEntryType} entry - entry товара, который обновляем
     *!/

    async updateEntry(catalogId: string, entry: CatalogEntryType): Promise<[CustomError | null, CatalogEntry | null]> {
        const url = `/catalogs/${catalogId}/entries/${entry.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:catalog:createEntry]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: entry });
    }*/
    /*
    /!**
     * Удаление связи каталог-товар
     * @param {String} catalogId - ID каталога у которого удаляется связь с товаром
     * @param {Object} entryId - id entry товара в каталоге
     *!/

    async removeEntry(catalogId: string, entryId: string): Promise<[CustomError | null, null]> {
        const url = `/catalogs/${catalogId}/entries/${entryId}`;
        const defaultValue = null;
        const errorPath: string = '[api:catalog:removeEntry]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }*/
    /**
     * Получение статей
     * @param {SortingRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов статей
     */

    async getAll(params: SortingRequestParams = {}): Promise<[CustomError | null, Article[] | []]> {
        const url = `/articles`;
        const defaultValue = [] as [];
        const errorPath = '[api:articles:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config: { params } });
    }
    /**
     * Получение отдельной статьи
     * @param {String} id - id статьи
     * @returns {Promise} - объект статьи
     */

    async getOne(id: string): Promise<[CustomError | null, Article | null]> {
        const url = `/articles/${id}`;
        const defaultValue = null;
        const errorPath = '[api:article:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }
    /**
     * Создание статьи
     * @param {Object} article - объект статьи
     * @returns {Promise} - кастомный объект, содержащий ответ сервера
     */

    async createOne(article: Article): Promise<[CustomError | null, Article | null]> {
        const url = `/articles`;
        const defaultValue = null;
        const errorPath = '[api:article:createOne]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: article });
    }
    /**
     * Обновление статьи
     * @param {Object} article - объект статьи
     * @returns {Promise} - кастомный объект, содержащий ответ сервера
     */

    async updateOne(article: Article): Promise<[CustomError | null, Article | null]> {
        const url = `/articles/${article.id}`;
        const defaultValue = null;
        const errorPath = '[api:article:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: article });
    }

    /**
     * Обновление нескольких статей
     * @param {Array} articles - массив объектов статей
     */
    async updateBatch(articles: Article[]): Promise<[CustomError | null, Article[] | []]> {
        const url = `/articles/batch`;
        const defaultValue = [] as [];
        const errorPath = '[api:catalog:updateBatch]';
        const config = { params: { ids: articles.map((article) => article.id) } };
        return await ApiClient.admin.put({
            url,
            defaultValue,
            errorPath,
            data: articles,
            config,
        });
    }

    /**
     * Удаление статьи
     * @param {Object} id - объект статьи
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url = `/articles/${id}`;
        const defaultValue = null;
        const errorPath = '[api:article:removeOne]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }

    /**
     * Создание комментария для стать
     * @param {String} articleId - ID статьи для которой создается комментарий
     * @param {CatalogEntryType} comment - объект комментария
     */

    async createComment(articleId: string, comment: Comment): Promise<[CustomError | null, Comment | null]> {
        const url = `/articles/${articleId}/entries`;
        const defaultValue = null;
        const errorPath = '[api:article:createComment]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: comment });
    }
    /**
     * Обновление комментария для стать
     * @param {String} articleId - ID статьи для которой обновляется комментарий
     * @param {CatalogEntryType} comment - объект комментария
     */

    async updateComment(comment: Comment): Promise<[CustomError | null, Comment | null]> {
        const url = `/comments/${comment.id}`;
        const defaultValue = null;
        const errorPath = '[api:article:updateComment]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: comment });
    }
    /**
     * Создание комментария для стать
     * @param {String} id - ID комментария для удаления
     */

    async removeComment(id: string): Promise<[CustomError | null, Comment | null]> {
        const url = `/comments/${id}`;
        const defaultValue = null;
        const errorPath = '[api:article:removeComment]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }

    /*  /!**
     * Получение товаров из конкретного каталога
     * @param {String} id - ID каталога из которого нужно получить товары
     * @param {Object} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} массив объектов товаров
     *!/

    async getProductsInCatalog(id: string, params = {}): Promise<[CustomError | null, Product[] | []]> {
        const url = `/catalogs/v2/${id}/products`;
        const defaultValue = [] as [];
        const errorPath: string = '[api:catalog:getProductsInCatalog]';
        const config = { params };
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /!**
     * Получение связей каталога
     * @param {String} id - ID каталога, связи которого нужно получить
     * @returns {Promise} массив объектов связей каталог-товар
     *!/
*/
    async getAllComments(params: SortingRequestParams = {}): Promise<[CustomError | null, Comment[] | []]> {
        const url = `/comments`;
        const defaultValue = [] as [];
        const errorPath = '[api:article:getAllComments]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config: { params } });
    }

    async getImages(
        articles: Article[]
    ): Promise<[CustomError | null, { [key: string]: string } | { [key: string]: unknown }]> {
        const map = {} as { [key: string]: string };
        const imagesIds: string[] = [];
        articles.forEach((article) => {
            if (article.previewImageId) {
                map[article.previewImageId] = article.id;
                imagesIds.push(article.previewImageId);
            }
        });
        if (!imagesIds.length) {
            return [null, {}];
        }
        const imagesObj = {} as { [key: string]: Image };
        const [error, images] = await ImageService.getAll({ ids: imagesIds });
        images.forEach((image) => {
            const articleId = map[image?.id];
            imagesObj[articleId] = image;
        });
        return [error, imagesObj];
    }
}

export default new ArticleService();
