<template>
    <div class="input-icon" @click.prevent>
        <div class="input-icon__field">
            <div class="input-icon__field-text">
                <i v-if="value" class="material-icons-outlined" :style="{ color }">{{ value }}</i>
                <span>{{ text }}</span>
                <Button
                    v-if="hasCurrent"
                    class="input-icon__btn-clear"
                    type="icon"
                    icon="cancel"
                    @click="clearCurrent"
                />
            </div>
            <button class="btn btn-primary input-icon__field-btn" type="button" @click="openModal">Choose</button>
        </div>
        <modal attach="body" header="Choose icon" v-model="isOpen" :width="'850'">
            <IconManager v-model="model" :items="items" />
        </modal>
    </div>
</template>

<script>
import Modal from '@/components/common/Modal';
import IconManager from '@/components/form/controls/IconManager.vue';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Button from '@/components/common/Button.vue';

export default {
    name: 'InputIcon',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        value: { type: [String, Number, Object, Date] },
        items: { type: [Array], default: () => [] },
        placeholder: { type: String, default: 'Nothing' },
        color: { type: String, default: '' },
    },
    mixins: [proxyModelMixin],
    components: {
        Modal,
        IconManager,
        Button,
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        text() {
            return this.value ? this.value : this.placeholder;
        },
        hasCurrent() {
            return !!this.model;
        },
    },
    methods: {
        openModal() {
            this.isOpen = true;
        },
        clearCurrent(e) {
            e.preventDefault();
            this.model = null;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.input-icon {
    &__field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--v-surface-base);
        &-text {
            position: relative;
            display: flex;
            align-items: center;
            gap: 6px;
            flex: 1;
            border: 1px solid #cececf;
            border-radius: 5px 0 0 5px;
            padding: 0 12px;
            height: 40px;
            line-height: 38px;
        }
        &-btn {
            height: 40px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &__btn-clear {
        position: absolute;
        right: 3px;
        top: 2px;
    }
}
</style>
