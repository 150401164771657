import { uuid } from 'vue-uuid';
import { cloneDeep } from 'lodash';
import ApiClient from '@/api/apiClient';
import { createSettingsList } from '@/entities/setting/SettingHelper';
import { SettingsRequestParams } from '@/services/services.types';

class SettingService {
    async getAll(params: SettingsRequestParams = {}) {
        const url = `/config`;
        const defaultValue = {};
        const config = { params };
        const errorPath = '[api:setting:getAll]';
        const [error, result] = await ApiClient.admin.get({ url, defaultValue, errorPath, config });
        const loadedSettings = error ? {} : result;
        // костыль для цветов
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { styles, themes } = loadedSettings;
        if (themes) {
            Object.keys(themes).forEach((key) => {
                themes[key].id = uuid.v4();
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (styles && themes) loadedSettings.styles = { ...themes, ...styles };
        }
        return [error, createSettingsList(loadedSettings)];
    }
    //Похоже, что этот метод нигде не используется
    async updateAll(settings: any) {
        const url = `/config`;
        const defaultValue = {};
        const errorPath = '[api:setting:updateAll]';
        const cloneSetting = cloneDeep(settings);
        const settingsGroup = Object.keys(cloneSetting);
        settingsGroup.forEach((group) => {
            const props = Object.keys(cloneSetting[group]);
            props.forEach((prop) => {
                cloneSetting[group][prop] = {
                    id: cloneSetting[group][prop].id,
                    value: cloneSetting[group][prop].value,
                    type: cloneSetting[group][prop].type,
                    position: cloneSetting[group][prop].position,
                };
            });
        });
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: cloneSetting });
    }

    async updateGroup(group: string, settings: any) {
        const url = `/config/${group}`;
        const defaultValue = {};
        const errorPath = '[api:setting:updateGroup]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: settings });
    }

    async updateBatch(groups: string[], settings: any) {
        const url = `/config/batch`;
        const defaultValue = {};
        const errorPath = '[api:setting:updateBatch]';
        const config = { params: { groups } };
        return await ApiClient.admin.post({ url, defaultValue, errorPath, config, data: settings });
    }
}

export default new SettingService();
