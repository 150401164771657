export default {
    columns: {
        amount: 'Amount',
        clientInfo: 'Client Info',
        color: 'Color',
        conditions: 'Conditions',
        createdAt: 'Created At',
        date: 'Date',
        dateEnd: 'Start date',
        dateNextBilling: 'Next billing date',
        dateStart: 'End date',
        default: 'Default',
        delivery: 'Delivery',
        discount: 'Discount',
        domain: 'Domain',
        email: 'Email',
        enabled: 'Enabled',
        fromUrl: 'From URL',
        fullPrice: 'Full Price',
        hidden: 'Hidden',
        id: 'ID',
        image: 'Image',
        item: 'Item',
        manager: 'Manager',
        name: 'Name',
        options: 'Options',
        order: 'Order',
        payment: 'Payment',
        position: 'Position',
        price: 'Price',
        priceAfterDiscount: 'Price after Discount',
        primary: 'Primary',
        product: 'Product',
        products: 'Products',
        published: 'Published',
        quantity: 'Quantity',
        rateName: 'Rate Name',
        registeredDate: 'Registered At',
        reviews: 'Reviews',
        role: 'Role',
        shippingSpeed: 'Shipping Speed',
        sku: 'SKU',
        status: 'Status',
        stock: 'Stock',
        toObject: 'To Object',
        subjectType: 'Object Type',
        text: 'Text',
        title: 'Name',
        toUrl: 'To URL',
        total: 'Total',
        type: 'Type',
        unit: 'Unit',
        values: 'Values',
        view: 'Views',
        number: 'Number',
        phone: 'Phone',
        comment: 'Comment',
        state: 'State',
        messageFromClient: 'Message from client',
        size: 'Size (cm)',
        weight: 'Weight (kg)',
        publicName: 'Public name',
        serviceName: 'Name',
        deleted: 'Deleted',
        photoId: 'Photo',
        bio: 'Bio',
        articles: 'Articles',
        jobTitle: 'Job title',
        tagIds: 'Tags',
        annotation: 'Post teaser',
        'author/publisher': 'Author / Publisher',
        publishedAt: 'Published at',
        updatedAt: 'Last modified',
        comments: 'Comments',
        articleIds: 'Articles',
        articleId: 'Article',
        categoryId: 'Category',
        articlesCreatedIds: 'Written articles',
        catalogs: 'Catalogs',
        icon: 'Icon',
        usage: 'Usage',
        useInFilter: 'Use in filters',
        gtin: 'gtin',
    },

    addButton: {
        order: 'Add Order',
        page: 'Add Page',
        catalog: 'Add Catalog',
        option: 'Add Option',
        review: 'Add Review',
        product: 'Add Product',
        label: 'Add Label',
        location: 'Add Location',
        shippingZone: 'Add Shipping Zone',
        rate: 'Add Rate',
        user: 'Add User',
        domain: 'Add Domain',
        orderState: 'Add Order Status',
        tag: 'Add Tag',
        property: 'Add Characteristic',
    },

    editButton: {
        order: 'Edit Order',
        page: 'Edit Page',
        catalog: 'Edit Catalog',
        option: 'Edit Option',
        review: 'Edit Review',
        product: 'Edit Product',
        label: 'Edit Label',
        shippingZone: 'Edit Shipping Zone',
        rate: 'Edit Rate',
        user: 'Edit User',
        domain: 'Change Domain Type',
        lead: 'Edit Lead',
        redirect: 'Edit Redirect',
        orderState: 'Edit Order Status',
        tag: 'Edit Tag',
        property: 'Edit Characteristic',
    },

    deleteButton: {
        order: 'Delete Order',
        page: 'Delete Page',
        catalog: 'Delete Catalog',
        option: 'Delete Option',
        review: 'Delete Review',
        product: 'Delete Product',
        label: 'Delete Label',
        lead: 'Delete Lead',
    },

    actions: {
        editInBuilder: 'Edit in Builder',
        deleteSelected: 'Delete Selected',
        importProducts: 'Import Products',
        showSelected: 'Show Selected',
        hideSelected: 'Hide Selected',
        disableSelected: 'Disable Selected',
        enableSelected: 'Enable Selected',
        viewAll: 'View All',
        deleteAll: 'Delete All',
    },
    perPage: 'Show {count} results',
    text: {
        from: 'from',
        priceOnRequest: 'Price on request',
    },
    noItems: 'No items found',
    loadingItems: 'Loading items',
};
