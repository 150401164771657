import apiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { ProductLabel } from '@/services/services.types';

class ProductLabelService {
    /**
     * Получение лейблов
     * @returns {Promise} - массив объектов лейблов
     */

    async getAll(): Promise<[CustomError | null, ProductLabel[] | []]> {
        const url = `/product-labels`;
        const defaultValue = [] as [];
        const errorPath = '[api:productLabel:getAll]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Получение конкретного лейбла
     * @param {String} id - id лейбла
     * @returns {Promise} - объект лейбла
     */

    async getOne(id: string): Promise<[CustomError | null, ProductLabel | null]> {
        const url = `/product-labels/${id}`;
        const defaultValue = null;
        const errorPath = '[api:productLabel:getOne]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание лейбла
     * @param {ProductLabel} label - объект лейбла
     * @returns {Promise} - объект созданного лейбла
     */

    async createOne(label: ProductLabel): Promise<[CustomError | null, ProductLabel | null]> {
        const url = `/product-labels`;
        const defaultValue = null;
        const errorPath = '[api:productLabel:createOne]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: label });
    }

    /**
     * Обновление конкретного лейбла
     * @param {ProductLabel} label - объект лейбла
     * @returns {Promise} - объект обновленного лейбла
     */

    async updateOne(label: ProductLabel): Promise<[CustomError | null, ProductLabel | null]> {
        const url = `/product-labels/${label.id}`;
        const defaultValue = null;
        const errorPath = '[api:productLabel:updateOne]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: label });
    }

    /**
     * Обновление лейблов
     * @param {Array} labels - массив лейблов
     * @returns {Promise} - массив обновлённых лейблов
     */

    async updateBatch(labels: ProductLabel[]): Promise<[CustomError | null, ProductLabel[] | []]> {
        const url = `/product-labels/batch`;
        const defaultValue = [] as [];
        const errorPath = '[api:productLabel:updateBatch]';
        const config = {
            params: {
                ids: labels.map((productLabel) => productLabel.id),
            },
        };
        return await apiClient.admin.put({ url, defaultValue, errorPath, config, data: labels });
    }

    /**
     * Удаление лейбла
     * @param {String} id - id лейбла
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url = `/product-labels/${id}`;
        const defaultValue = null;
        const errorPath = '[api:productLabel:removeOne]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new ProductLabelService();
