<template>
    <div class="input-text" :class="classes">
        <div v-if="prefix" class="prefix">{{ translatePrefix ? $t(prefix) : prefix }}</div>
        <input
            :disabled="disabled"
            class="input-text__field"
            v-on:change="onChange"
            v-model="model"
            v-bind="inputProps"
        />
        <div v-if="isShowNullValue" class="input-text__null-value">{{ nullValue }}</div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'InputText',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: [String, Number], default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
        disabled: { type: Boolean, default: false },
        prefix: { type: String },
        translatePrefix: { type: Boolean, default: false },
        nullValue: { type: String, default: '' },
        min: { type: Number, default: null },
        max: { type: Number, default: null },
    },
    computed: {
        inputProps() {
            return {
                type: this.type,
                name: this.name,
                placeholder: this.$t(this.placeholder),
                min: this.min,
                max: this.max,
            };
        },
        hasError: function() {
            return Array.isArray(this.errors) && this.errors.length > 0;
        },
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-disabled': this.disabled,
            };
        },
        isShowNullValue() {
            return (this.model === null || this.model === '') && this.nullValue;
        },
    },
    methods: {
        onChange(e) {
            if (this.type === 'number' && (this.max || this.min)) {
                this.checkMinMax();
            }
            this.$emit('change', e.target.value);
        },
        checkMinMax() {
            if (!this.model) return;
            if (this.max !== null && this.model > this.max) {
                this.model = this.max;
            } else if (this.min !== null && this.model < this.min) {
                this.model = this.min;
            }
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.input-text {
    position: relative;
    border: $form-control-border;
    border-radius: $form-control-border-radius;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: 40px;
    padding: 0 $form-control-padding-horizontal;
    background: $form-control-bg;
    transition: $form-control-transition;

    &:hover {
        border-color: var(--v-on-surface-medium-base);
    }
    &:focus-within {
        border-color: var(--v-primary-accent-base);
    }
    &__field {
        display: block;
        width: 100%;
        max-height: 32px;
        padding: 8px 0 8px;
        &:focus {
            outline: none;
        }
        appearance: none;
        &::placeholder {
            color: inherit;
            opacity: 0.7;
        }
    }

    .prefix {
        line-height: 1.4256rem;
        color: var(--v-on-surface-medium-base);
        margin-right: 5px;
    }

    &.-has-errors {
        border-color: $error;
    }
    &.-disabled {
        background-color: #f3f3f3;
        color: var(--v-on-surface-disabled-base);
        &:hover {
            border-color: $form-control-border-color !important;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield; /* Firefox */
    }

    input::placeholder {
        font-style: italic;
    }

    input {
        border: none;
    }

    &__null-value {
        position: absolute;
        left: 12px;
        top: 5px;
        font-size: 1.4rem;
        pointer-events: none;

        input:focus + & {
            display: none;
        }
    }
}
</style>
