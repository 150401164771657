export default [
    // Actions Group
    { text: 'account_balance', value: 'account_balance', group: 'actions' },
    { text: 'account_circle', value: 'account_circle', group: 'actions' },
    { text: 'assignment', value: 'assignment', group: 'actions' },
    { text: 'bolt', value: 'bolt', group: 'actions' },
    { text: 'build', value: 'build', group: 'actions' },
    { text: 'calendar_today', value: 'calendar_today', group: 'actions' },
    { text: 'credit_card', value: 'credit_card', group: 'actions' },
    { text: 'delete_outline', value: 'delete_outline', group: 'actions' },
    { text: 'description', value: 'description', group: 'actions' },
    { text: 'done', value: 'done', group: 'actions' },
    { text: 'event_available', value: 'event_available', group: 'actions' },
    { text: 'favorite', value: 'favorite', group: 'actions' },
    { text: 'favorite_border', value: 'favorite_border', group: 'actions' },
    { text: 'filter_alt', value: 'filter_alt', group: 'actions' },
    { text: 'fingerprint', value: 'fingerprint', group: 'actions' },
    { text: 'help', value: 'help', group: 'actions' },
    { text: 'home', value: 'home', group: 'actions' },
    { text: 'info', value: 'info', group: 'actions' },
    { text: 'invert_colors', value: 'invert_colors', group: 'actions' },
    { text: 'lightbulb', value: 'lightbulb', group: 'actions' },
    { text: 'lock', value: 'lock', group: 'actions' },
    { text: 'local_shipping', value: 'local_shipping', group: 'actions' },
    { text: 'login', value: 'login', group: 'actions' },
    { text: 'logout', value: 'logout', group: 'actions' },
    { text: 'outlet', value: 'outlet', group: 'actions' },
    { text: 'shopping_bag', value: 'shopping_bag', group: 'actions' },
    { text: 'pets', value: 'pets', group: 'actions' },
    { text: 'print', value: 'print', group: 'actions' },
    { text: 'rocket_launch', value: 'rocket_launch', group: 'actions' },
    { text: 'savings', value: 'savings', group: 'actions' },
    { text: 'schedule', value: 'schedule', group: 'actions' },
    { text: 'search', value: 'search', group: 'actions' },
    { text: 'settings', value: 'settings', group: 'actions' },
    { text: 'shopping_cart', value: 'shopping_cart', group: 'actions' },
    { text: 'star_outline', value: 'star_outline', group: 'actions' },
    { text: 'verified', value: 'verified', group: 'actions' },
    { text: 'visibility_off', value: 'visibility_off', group: 'actions' },

    // Alert Group
    { text: 'add_alert', value: 'add_alert', group: 'alert' },
    { text: 'error', value: 'error', group: 'alert' },
    { text: 'error_outline', value: 'error_outline', group: 'alert' },
    { text: 'notification_important', value: 'notification_important', group: 'alert' },
    { text: 'warning', value: 'warning', group: 'alert' },
    { text: 'warning_amber', value: 'warning_amber', group: 'alert' },

    // Audio_Video Group
    { text: 'stop_circle', value: 'stop_circle', group: 'audio_video' },
    { text: 'play_circle', value: 'play_circle', group: 'audio_video' },
    { text: 'videocam', value: 'videocam', group: 'audio_video' },
    { text: 'loop', value: 'loop', group: 'audio_video' },
    { text: 'mic', value: 'mic', group: 'audio_video' },
    { text: 'music_video', value: 'music_video', group: 'audio_video' },
    { text: 'not_interested', value: 'not_interested', group: 'audio_video' },
    { text: 'pause_circle', value: 'pause_circle', group: 'audio_video' },
    { text: 'volume_up', value: 'volume_up', group: 'audio_video' },
    { text: 'volume_off', value: 'volume_off', group: 'audio_video' },

    // Business Group
    { text: 'bar_chart_4_bars', value: 'bar_chart_4_bars', group: 'business' },
    { text: 'calculate', value: 'calculate', group: 'business' },
    { text: 'timeline', value: 'timeline', group: 'business' },
    { text: 'trending_up', value: 'trending_up', group: 'business' },
    { text: 'work', value: 'work', group: 'business' },
    { text: 'wallet', value: 'wallet', group: 'business' },
    // { text: 'monitoring', value: 'monitoring', group: 'business' },
    // { text: 'database', value: 'database', group: 'business' },
    // { text: 'receipt', value: 'receipt', group: 'new' },
    // { text: 'shoppingmode', value: 'shoppingmode', group: 'new' },

    // Common Group
    { text: 'auto_awesome', value: 'auto_awesome', group: 'common' },
    { text: 'account_balance_wallet', value: 'account_balance_wallet', group: 'business' },
    { text: 'arrow_back', value: 'arrow_back', group: 'common' },
    { text: 'arrow_back_ios', value: 'arrow_back_ios', group: 'common' },
    { text: 'arrow_forward', value: 'arrow_forward', group: 'common' },
    { text: 'arrow_forward_ios', value: 'arrow_forward_ios', group: 'common' },
    { text: 'arrow_upward', value: 'arrow_upward', group: 'common' },
    { text: 'arrow_downward', value: 'arrow_downward', group: 'common' },
    { text: 'bar_chart', value: 'bar_chart', group: 'editor' },
    { text: 'bookmark', value: 'bookmark', group: 'common' },
    { text: 'chevron_left', value: 'chevron_left', group: 'common' },
    { text: 'chevron_right', value: 'chevron_right', group: 'common' },
    { text: 'code', value: 'code', group: 'common' },
    { text: 'construction', value: 'construction', group: 'common' },
    { text: 'group', value: 'group', group: 'common' },
    { text: 'emoji_events', value: 'emoji_events', group: 'common' },
    { text: 'question_mark', value: 'question_mark', group: 'common' },
    { text: 'laptop_mac', value: 'laptop_mac', group: 'device' },
    { text: 'warehouse', value: 'warehouse', group: 'common' },
    { text: 'person', value: 'person', group: 'common' },
    { text: 'priority_high', value: 'priority_high', group: 'common' },
    { text: 'school', value: 'school', group: 'common' },
    { text: 'crop', value: 'crop', group: 'common' },

    // Communication Group
    // { text: 'add_call', value: 'add_call', group: 'communication' },
    { text: 'alternate_email', value: 'alternate_email', group: 'communication' },
    { text: 'email', value: 'email', group: 'communication' },
    // { text: 'call', value: 'call', group: 'communication' },
    { text: 'chat_bubble_outline', value: 'chat_bubble_outline', group: 'communication' },
    { text: 'call_end', value: 'call_end', group: 'communication' },
    { text: 'cell_tower', value: 'cell_tower', group: 'communication' },
    { text: 'comment', value: 'comment', group: 'communication' },
    { text: 'forum', value: 'forum', group: 'communication' },
    { text: 'hourglass_bottom', value: 'hourglass_bottom', group: 'communication' },
    { text: 'hourglass_empty', value: 'hourglass_empty', group: 'communication' },
    { text: 'qr_code_2', value: 'qr_code_2', group: 'communication' },
    { text: 'import_export', value: 'import_export', group: 'communication' },
    { text: 'link', value: 'link', group: 'communication' },
    { text: 'location_on', value: 'location_on', group: 'communication' },
    { text: 'message', value: 'message', group: 'communication' },
    { text: 'phone', value: 'phone', group: 'communication' },
    { text: 'sms', value: 'sms', group: 'communication' },
    { text: 'swap_calls', value: 'swap_calls', group: 'communication' },
    { text: 'timelapse', value: 'timelapse', group: 'communication' },
    { text: 'vpn_key', value: 'vpn_key', group: 'communication' },

    // Content Group
    { text: 'add', value: 'add', group: 'content' },
    { text: 'add_circle_outline', value: 'add_circle_outline', group: 'content' },
    { text: 'backspace', value: 'backspace', group: 'content' },
    { text: 'content_copy', value: 'content_copy', group: 'content' },
    { text: 'content_cut', value: 'content_cut', group: 'content' },
    { text: 'clear', value: 'clear', group: 'content' },
    { text: 'create', value: 'create', group: 'content' },
    { text: 'insights', value: 'insights', group: 'content' },
    { text: 'outlined_flag', value: 'outlined_flag', group: 'content' },
    { text: 'push_pin', value: 'push_pin', group: 'content' },
    { text: 'redo', value: 'redo', group: 'content' },
    { text: 'reply', value: 'reply', group: 'content' },
    { text: 'remove', value: 'remove', group: 'content' },
    { text: 'remove_circle_outline', value: 'remove_circle_outline', group: 'content' },
    { text: 'save_alt', value: 'save_alt', group: 'content' },
    { text: 'send', value: 'send', group: 'content' },
    { text: 'shield', value: 'shield', group: 'content' },
    { text: 'tag', value: 'tag', group: 'content' },
    { text: 'waves', value: 'waves', group: 'content' },

    // Device Group
    { text: 'airplanemode_active', value: 'airplanemode_active', group: 'device' },
    { text: 'battery_charging_full', value: 'battery_charging_full', group: 'device' },
    { text: 'light_mode', value: 'light_mode', group: 'device' },
    { text: 'gpp_good', value: 'gpp_good', group: 'device' },
    { text: 'gpp_maybe', value: 'gpp_maybe', group: 'device' },
    { text: 'gpp_bad', value: 'gpp_bad', group: 'device' },
    { text: 'restart_alt', value: 'restart_alt', group: 'device' },
    { text: 'sell', value: 'sell', group: 'device' },
    { text: 'thermostat', value: 'thermostat', group: 'device' },

    // Editor Group
    { text: 'area_chart', value: 'area_chart', group: 'editor' },
    { text: 'attach_file', value: 'attach_file', group: 'editor' },
    { text: 'bubble_chart', value: 'bubble_chart', group: 'editor' },
    { text: 'format_list_bulleted', value: 'format_list_bulleted', group: 'editor' },
    { text: 'format_quote', value: 'format_quote', group: 'editor' },
    { text: 'insert_drive_file', value: 'insert_drive_file', group: 'editor' },
    { text: 'monetization_on', value: 'monetization_on', group: 'editor' },
    { text: 'mode', value: 'mode', group: 'editor' },

    // Faile Group
    { text: 'cloud', value: 'cloud', group: 'fаile' },
    { text: 'cloud_upload', value: 'cloud_upload', group: 'fаile' },
    { text: 'drive_folder_upload', value: 'drive_folder_upload', group: 'fаile' },
    { text: 'cloud_download', value: 'cloud_download', group: 'fаile' },
    { text: 'file_download', value: 'file_download', group: 'fаile' },
    { text: 'file_upload', value: 'file_upload', group: 'fаile' },
    { text: 'folder', value: 'folder', group: 'fаile' },

    // Maps Group
    { text: 'explore', value: 'explore', group: 'maps' },
    { text: 'map', value: 'map', group: 'maps' },
    { text: 'my_location', value: 'my_location', group: 'maps' },
    { text: 'navigation', value: 'navigation', group: 'maps' },
    { text: 'restaurant', value: 'restaurant', group: 'maps' },
    { text: 'restaurant_menu', value: 'restaurant_menu', group: 'maps' },
    { text: 'tour', value: 'tour', group: 'maps' },

    // Social Group
    { text: 'thumb_up', value: 'thumb_up', group: 'social' },
    { text: 'thumb_down', value: 'thumb_down', group: 'social' },
    { text: 'groups', value: 'groups', group: 'nesocialw' },
    { text: 'public', value: 'public', group: 'social' },
    { text: 'person_add', value: 'person_add', group: 'social' },
    { text: 'eco', value: 'eco', group: 'social' },
    { text: 'workspace_premium', value: 'workspace_premium', group: 'social' },
    { text: 'gavel', value: 'gavel', group: 'social' },
    { text: 'recycling', value: 'recycling', group: 'social' },
    { text: 'diamond', value: 'diamond', group: 'social' },
    { text: 'whatshot', value: 'whatshot', group: 'social' },

    // Transportation Group
    { text: 'local_shipping', value: 'local_shipping', group: 'transportation' },
    { text: 'directions_bike', value: 'directions_bike', group: 'transportation' },
    { text: 'directions_boat', value: 'directions_boat', group: 'transportation' },
    { text: 'directions_car', value: 'directions_car', group: 'transportation' },
    { text: 'directions_run', value: 'directions_run', group: 'transportation' },
    { text: 'flight', value: 'flight', group: 'transportation' },
    { text: 'flight_takeoff', value: 'flight_takeoff', group: 'transportation' },

    // Goods Group
    { text: 'local_cafe', value: 'local_cafe', group: 'goods' },
    { text: 'coffee', value: 'coffee', group: 'goods' },
    { text: 'free_breakfast', value: 'free_breakfast', group: 'goods' },
    { text: 'emoji_food_beverage', value: 'emoji_food_beverage', group: 'goods' },
    { text: 'coffee_maker', value: 'coffee_maker', group: 'goods' },
];
