<template>
    <div class="customize-media" :class="{ '-disabled': disabled }">
        <Select class="customize-media__item" v-model="value.type" :placeholder="'Choose media'" :items="types" />
        <FormItem
            v-if="value.type === 'image'"
            :labelTrans="'builder.sectionOptions.props.image'"
            class="customize-media__item"
        >
            <InputImageBuilder v-model="value.image" note="" :btnReset="true" />
        </FormItem>
        <FormItem
            v-if="value.type === 'image'"
            class="customize-media__item"
            :labelTrans="'builder.sectionOptions.props.imageAlt'"
            :note="'builder.sectionOptions.props.imageAlt'"
        >
            <InputText v-model="value.imageAlt" />
        </FormItem>
        <FormItem
            v-if="value.type === 'video'"
            class="customize-media__item"
            :labelTrans="'builder.sectionOptions.props.source'"
        >
            <Select v-model="value.source" :placeholder="'Choose source'" :items="source" />
        </FormItem>
        <FormItem
            v-if="value.type === 'video'"
            class="customize-media__item"
            :labelTrans="'builder.sectionOptions.props.video'"
            :note="'builder.sectionOptions.props.videoNote'"
        >
            <InputText v-model="value.video" @change="onChangeVideo" />
        </FormItem>
        <FormItem class="customize-media__item" :labelTrans="'builder.sectionOptions.props.ratio'">
            <Select v-model="value.ratio" :placeholder="'Choose ratio'" :items="ratios" />
        </FormItem>
        <FormItem
            v-if="value.ratio === 'height'"
            class="customize-media__item"
            :labelTrans="'builder.sectionOptions.props.height'"
        >
            <InputNumberAndRange v-model="value.customHeight" :max="1000" />
        </FormItem>
        <FormItem class="customize-media__item" :labelTrans="'builder.sectionOptions.props.colorOverlay'">
            <ColorPicker v-model="value.color" />
        </FormItem>
        <FormItem class="customize-media__item" :labelTrans="'builder.sectionOptions.props.opacity'">
            <InputRange v-model="value.opacity" />
        </FormItem>
        <template v-if="value.type === 'video'">
            <FormItem class="customize-media__item" :labelTrans="'builder.sectionOptions.props.usePreviewImage'">
                <InputSwitch
                    :size="'small'"
                    v-model="value.usePreviewImage"
                    :text="'builder.sectionOptions.props.usePreviewImageText'"
                />
            </FormItem>
            <FormItem
                v-if="value.usePreviewImage"
                :noLabel="true"
                :labelTrans="'builder.sectionOptions.props.previewImage'"
                class="customize-media__item"
            >
                <InputImageBuilder v-model="value.previewImage" note="" :btnReset="true" />
            </FormItem>
        </template>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import FormItem from '@/components/form/item.vue';
import InputSwitch from '@/components/form/controls/InputSwitch.vue';
export default {
    name: 'CustomizeMedia',
    mixins: [proxyModelMixin],
    components: {
        InputSwitch,
        FormItem,
    },
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'array' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Number, String, Object], default: null },
        disabled: { type: Boolean, default: false },
    },
    model: {
        prop: 'value',
        event: 'change-value',
    },
    data() {
        return {
            types: [
                { text: 'Video', value: 'video' },
                { text: 'Image', value: 'image' },
            ],
            source: [
                { text: 'YouTube', value: 'youtube' },
                { text: 'Vimeo', value: 'vimeo' },
                { text: 'Rutube', value: 'rutube' },
                { text: 'Kinescope', value: 'kinescope' },
            ],
            ratios: [
                { text: 'custom height', value: 'height' },
                { text: 'by text', value: 'text' },
                { text: 'original', value: 'original' },
                { text: '1 : 1', value: '1' },
                { text: '4 : 3', value: '1.33' },
                { text: '16 : 9', value: '1.78' },
                { text: '2920 x 1080', value: '2.705' },
                { text: '2 : 1', value: '2' },
                { text: '3 : 1', value: '3' },
            ],
            curent: null,
            config: {
                type: 'image',
                source: 'youtube',
                ratio: '1.78',
                customHeight: 300,
                image: null,
                imageAlt: null,
                video: null,
                color: null,
                opacity: 50,
                usePreviewImage: false,
                previewImage: null,
            },
        };
    },
    mounted() {
        this.curent = { ...this.config, ...this.value };
        this.$emit('change-value', this.curent);
    },
    methods: {
        onChangeVideo(data) {
            const isTag = data.indexOf('src="');
            if (isTag >= 0) return (this.curent.video = this.getVideoId(data, 'src="https://www.youtube.com/embed/'));
            if (data.indexOf('https://youtu.be/') >= 0) this.curent.video = this.getVideoId(data, 'https://youtu.be/');
            if (data.indexOf('https://www.youtube.com/watch?v=') >= 0)
                this.curent.video = this.getVideoId(data, 'https://www.youtube.com/watch?v=', '&');
            if (data.indexOf('https://www.youtube.com/embed/') >= 0)
                this.curent.video = this.getVideoId(data, 'https://www.youtube.com/embed/');
            if (data.indexOf('https://vimeo.com/') >= 0)
                this.curent.video = this.getVideoId(data, 'https://vimeo.com/');
        },
        getVideoId(data, str, endSimbol = '?') {
            const contentStart = data.indexOf(str) + str.length;
            let content = data.slice(contentStart);
            const contentEnd = content.includes(endSimbol) ? content.indexOf(endSimbol) : content.length;
            content = content.slice(0, contentEnd);
            return content;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.customize-media {
    &__item {
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}
</style>
