<template>
    <div class="icon-manager">
        <div class="icon-manager__header">
            <div class="icon-manager__input">
                <Select
                    class="icon-manager__input-field"
                    v-model="currentGroup"
                    :placeholder="'Category'"
                    :items="groups"
                    @input="onInput"
                />
            </div>
            <div class="icon-manager__input">
                <div class="icon-manager__input-field icon-search">
                    <input
                        v-model="search"
                        class="icon-search__input"
                        type="text"
                        :placeholder="$t('builder.topbar.pageSwitcher.search')"
                    />
                    <button class="icon-search__button" type="submit">
                        <span class="material-icons-outlined">search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="icon-manager__container">
            <div class="icon-manager__list">
                <div
                    class="icon-manager-item"
                    :class="{ 'is-selected': item.value === value }"
                    v-for="item in getFilteredItems(icons)"
                    :key="item.name"
                    @click="chooseIcon(item.value)"
                >
                    <i class="material-icons-outlined">{{ item.value }}</i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Select from '@/components/form/controls/Select.vue';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
export default {
    name: 'IconManager',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        value: { type: [String, Number, Object, Date] },
        items: { type: [Array], default: () => [] },
    },
    mixins: [proxyModelMixin],
    components: {
        Select,
    },
    data() {
        return {
            currentGroup: '',
            groups: [
                { value: 'all', text: 'All' },
                { value: 'actions', text: 'Actions' },
                { value: 'alert', text: 'Alert' },
                { value: 'audio_video', text: 'Audio & Video' },
                { value: 'business', text: 'Business' },
                { value: 'communication', text: 'Communication' },
                { value: 'content', text: 'Content' },
                { value: 'device', text: 'Device' },
                { value: 'editor', text: 'Editor' },
                { value: 'fаile', text: 'File' },
                { value: 'maps', text: 'Maps' },
                { value: 'social', text: 'Social' },
                { value: 'transportation', text: 'Transportation' },
                { value: 'goods', text: 'Goods' },
            ],
            dropdownDirectionTop: false,
            isDropdownShow: false,
            search: '',
        };
    },
    computed: {
        icons() {
            return this.currentGroup ? this.items.filter((icon) => icon.group === this.currentGroup) : this.items;
        },
    },
    methods: {
        chooseIcon(value) {
            this.model = value;
        },
        getFilteredItems(items) {
            if (this.search) {
                return this.icons.filter((item) => {
                    const title = item.value.toLowerCase();
                    const searchTerm = this.search.toLowerCase().replace(/ /g, '_');
                    return title.indexOf(searchTerm) >= 0;
                });
            }
            return items;
        },
        onInput(value) {
            this.currentGroup = value === 'all' ? '' : value;
            this.$emit('change', this.currentGroup);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.icon-manager {
    &__input {
        flex: 1;
        &-title {
            margin-right: 0.5rem;
        }
        &-field {
        }
    }
    &__header {
        display: flex;
        align-items: flex-end;
        gap: 1rem;
    }
    &__container {
        padding-top: 1rem;
        min-height: 300px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &-item {
        position: relative;
        width: 14%;
        height: 7rem;
        cursor: pointer;
        transition: $transition-fast;
        text-align: center;
        color: var(--v-secondary-base);
        &:hover {
            color: var(--v-secondary-base);
        }
        i {
            text-align: center;
            font-size: 3rem;
            line-height: 7rem;
        }
        &.is-selected {
            background-color: var(--v-primary-lighten-base);
            border-radius: 4px;
            i {
                color: var(--v-secondary-base);
            }
            &::before {
                content: 'check_circle';
                position: absolute;
                top: 5px;
                right: 5px;
                display: block;
                font-family: 'Material Icons';
                font-size: 20px;
                line-height: 20px;
                color: var(--v-primary-base);
            }
        }
    }
}
.icon-search {
    position: relative;

    &__input {
        width: 100%;
        height: $input-height;
        padding: 0 12px;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        transition: $input-transition;
        line-height: $input-height - 2px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
            border-color: $input-border-color-hover;
        }
    }
    &__button {
        position: absolute;
        top: 10px;
        right: 12px;
        z-index: 4;
        width: 24px;
        height: 24px;
        font-size: 0;
        border: 0;
        background: none;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
    }
}
</style>
