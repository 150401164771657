<template>
    <div class="button-builder">
        <div style="padding-bottom: 10px">
            <InputSwitch @input="handleInput('isShow', $event)" :value="model.isShow" size="small" text="is show" />
        </div>
        <label class="button-builder__item">
            <span class="button-builder__title">Type:</span>
            <ButtonToggles
                v-if="!typesAsSelect"
                :value="model.type"
                @input="handleValueByType"
                :items="types"
            ></ButtonToggles>
            <Select v-else :value="model.type" @input="handleValueByType" :items="types"></Select>
        </label>
        <label class="button-builder__item" v-show="model.type === 'link'">
            <span class="button-builder__title">Link:</span>
            <InputLink @input="handleInput('url', $event)" :value="model.url"></InputLink>
        </label>
        <label class="button-builder__item">
            <span class="button-builder__title">Text:</span>
            <InputText @input="handleInput('text', $event)" :value="model.text"></InputText>
        </label>
        <form-item :errors="phoneError" v-if="['phone', 'whatsapp'].includes(model.type)">
            <label class="button-builder__item">
                <span class="button-builder__title">Value:</span>
                <InputText @input="handleInput('number', $event)" :value="model.number"></InputText>
            </label>
        </form-item>
        <label class="button-builder__item">
            <span class="button-builder__title">Style:</span>
            <Select @input="handleInput('styles', $event)" :value="model.styles" :items="buttonStyles"></Select>
        </label>
        <label v-show="value.styles === 'custom'" class="button-builder__item">
            <span class="button-builder__title">Color Text:</span>
            <ColorPicker @input="handleInput('textColor', $event)" :value="model.textColor" />
        </label>
        <label v-show="value.styles === 'custom'" class="button-builder__item">
            <span class="button-builder__title">Color Background:</span>
            <ColorPicker @input="handleInput('bgColor', $event)" :value="model.bgColor" />
        </label>
        <template v-if="!['phone', 'whatsapp'].includes(model.type)">
            <label class="button-builder__item">
                <span class="button-builder__title">Icon Left:</span>
                <InputIcon
                    @input="handleInput('iconLeft', $event)"
                    :value="model.iconLeft"
                    :items="icons"
                    :color="model.iconLeftColor"
                />
            </label>
            <label class="button-builder__item">
                <span class="button-builder__title">Icon Left Color:</span>
                <ColorPicker @input="handleInput('iconLeftColor', $event)" :value="model.iconLeftColor" />
            </label>
            <label class="button-builder__item">
                <span class="button-builder__title">Icon Right:</span>
                <InputIcon
                    @input="handleInput('iconRight', $event)"
                    :value="model.iconRight"
                    :items="icons"
                    :color="model.iconRightColor"
                />
            </label>
            <label class="button-builder__item">
                <span class="button-builder__title">Icon Right Color:</span>
                <ColorPicker @input="handleInput('iconRightColor', $event)" :value="model.iconRightColor" />
            </label>
        </template>
    </div>
</template>

<script>
import Select from '@/components/form/controls/Select.vue';
import InputText from '@/components/form/controls/InputText.vue';
import ColorPicker from '@/components/form/controls/ColorPicker.vue';
import ButtonToggles from '@/components/form/controls/ButtonToggles.vue';
import InputLink from '@/components/form/controls/InputLink.vue';
import InputIcon from '@/components/form/controls/InputIcon.vue';
import { mapGetters } from 'vuex';
import { cloneDeep, debounce } from 'lodash';
import validateField from '@/helpers/validator';
import FormItem from '@/components/form/item';

export default {
    name: 'ButtonBuilder',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: String, default: '' },
        icons: { type: [Array] },
        types: {
            type: [Array],
            default: () => [
                { text: 'Link', value: 'link' },
                { text: 'Popup', value: 'popup' },
                { text: 'Whatsapp', value: 'whatsapp' },
                { text: 'Phone', value: 'phone' },
            ],
        },
        errors: { type: [Array, Object], default: () => [] },
        typesAsSelect: { type: Boolean, default: true },
        value: {
            type: [Object],
            default() {
                return {
                    isShow: false,
                    type: 'popup',
                    styles: 'primary',
                    text: null,
                    number: null,
                    textColor: null,
                    bgColor: null,
                    iconLeft: null,
                    iconRight: null,
                    iconLeftColor: null,
                    iconRightColor: null,
                    url: null,
                };
            },
        },
    },
    components: { FormItem, InputLink, ButtonToggles, ColorPicker, InputText, Select, InputIcon },
    data() {
        return {
            initialValue: {},
            phoneError: [],
            buttonStyles: ['primary', 'secondary', 'outline', 'text', 'critical', 'custom'],
        };
    },
    created() {
        this.initialValue = cloneDeep(this.model);
        if (!this.model.number && this.model.value) {
            const newValue = cloneDeep(this.model);
            newValue.number = newValue.value;
            delete newValue.value;
            this.model = newValue;
        }
    },
    methods: {
        handleValueByType(type) {
            let newValue = cloneDeep(this.model);
            newValue.type = type;
            if (this.initialValue.type === type) {
                this.model = cloneDeep(this.initialValue);
                return;
            }
            if (['phone', 'whatsapp'].includes(type)) {
                const phone = this.config.common.phones.value[0];
                const whatsapp = this.config.common.whatsapps.value[0];
                newValue.text = type === phone ? phone : whatsapp;
                newValue.number = type === 'phone' ? phone : whatsapp;
            } else {
                newValue.text = null;
                newValue.number = null;
                newValue.url = null;
                newValue.iconLeft = null;
                newValue.iconLeftColor = null;
                newValue.iconRight = null;
                newValue.iconRightColor = null;
            }
            this.model = newValue;
        },
        validatePhone: debounce(function(val) {
            this.phoneError = validateField(val, ['phone']);
        }),
        handleInput(name, value) {
            const newValue = cloneDeep(this.model);
            newValue[name] = value;
            this.model = newValue;
            if (name === 'number') {
                this.validatePhone(value);
            }
        },
    },
    computed: {
        ...mapGetters('config', {
            config: 'getAllSettings',
        }),
        model: {
            get() {
                // console.log(this.currentButton);
                return this.value;
            },
            set(val) {
                // set(value) {
                // console.log('set', value);
                this.$emit('input', val);
            },
        },
    },
    watch: {
        value: {
            handler: function() {
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: this.value,
                });
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.button-builder {
    &__item {
        display: block;
        position: relative;
        width: 100%;
        &:hover {
            z-index: 15;
        }

        .select,
        .color-picker,
        .input-icon,
        .input-text {
            margin-top: -10px;
        }
        .input-icon {
            i {
                margin-top: 4px;
            }
        }
    }

    &__title {
        display: inline-block;
        padding: 0 5px;
        font-size: 0.75em;
        background: #fff;
        position: relative;
        z-index: 1;
        margin-left: 8px;
        border-radius: 4px;
    }
}
</style>
