import ButtonToggles from '@/components/form/controls/ButtonToggles';
import ButtonBuilder from '@/components/form/controls/ButtonBuilder';
// import CodeEditor from '@/components/form/controls/CodeEditor';
import InputSectionLayout from '@/components/form/controls/InputSectionLayout';
import CodeEditor from '@/components/form/controls/CKEditor';
import CodeEditorScreenTabs from '@/components/form/controls/CodeEditorScreenTabs';
import ColorPicker from '@/components/form/controls/ColorPicker';
import CompositeField from '@/components/form/controls/CompositeField.vue';
import CompositeFieldsScreenTabs from '@/components/form/controls/CompositeFieldsScreenTabs.vue';
import InputArray from '@/components/form/controls/InputArray';
import InputCheckbox from '@/components/form/controls/InputCheckbox';
import InputImage from '@/components/form/controls/InputImage';
import InputImageBuilder from '@/components/form/controls/InputImageBuilder';
import InputImageScreenBuilder from '@/components/form/controls/InputImageScreenBuilder';
import InputImageArrayBuilder from '@/components/form/controls/InputImageArrayBuilder';
import InputLink from '@/components/form/controls/InputLink';
import InputRange from '@/components/form/controls/InputRange';
import InputRangeScreenBuilder from '@/components/form/controls/InputRangeScreenBuilder';
import InputSwitch from '@/components/form/controls/InputSwitch';
import InputText from '@/components/form/controls/InputText';
import Select from '@/components/form/controls/Select';
import SelectBenefitIcon from '@/components/form/controls/SelectBenefitIcon';
import SelectCatalog from '@/components/form/controls/SelectCatalog';
import SelectCategory from '@/components/form/controls/SelectCategory';
import SelectProduct from '@/components/form/controls/SelectProduct';
import SelectScreenTabs from '@/components/form/controls/SelectScreenTabs';
import Textarea from '@/components/form/controls/Textarea';
import CustomizeColumns from '@/components/form/controls/CustomizeColumns';
import CustomizeColumnsTabs from '@/components/form/controls/CustomizeColumnsTabs';
import CustomizeColumnsRange from '@/components/form/controls/CustomizeColumnsRange';
import InputNumber from '@/components/form/controls/InputNumber';
import CustomizePadding from '@/components/form/controls/CustomizePadding';
import CustomizePaddingTabs from '@/components/form/controls/CustomizePaddingTabs';
import CustomizeBorderRadiusTabs from '@/components/form/controls/CustomizeBorderRadiusTabs';
import CustomizeBackground from '@/components/form/controls/CustomizeBackground';
import CustomizeBackgroundTabs from '@/components/form/controls/CustomizeBackgroundTabs';
import CustomizeMarginTabs from '@/components/form/controls/CustomizeMarginTabs';
import SelectBackground from '@/components/form/controls/SelectBackground';
import InputIcon from '@/components/form/controls/InputIcon';
import SelectPreviewSizeList from '@/components/form/controls/SelectPreviewSizeList';
import CustomizeMedia from '@/components/form/controls/CustomizeMedia';
import CustomizeMediaTabs from '@/components/form/controls/CustomizeMediaTabs';
import PageStatus from '@/components/form/controls/PageStatus';
import InputNumberAndRange from '@/components/form/controls/InputNumberAndRange';
import InputNumberAndRangeScreen from '@/components/form/controls/InputNumberAndRangeScreen';
import InputPaste from '@/components/form/controls/InputPaste';
import InputAlign from '@/components/common/InputAlign';
export default {
    ButtonToggles,
    ButtonBuilder,
    CodeEditor,
    CodeEditorScreenTabs,
    ColorPicker,
    CompositeField,
    CompositeFieldsScreenTabs,
    InputArray,
    InputCheckbox,
    InputImage,
    InputImageBuilder,
    InputImageScreenBuilder,
    InputImageArrayBuilder,
    InputLink,
    InputRange,
    InputRangeScreenBuilder,
    InputSwitch,
    InputText,
    Select,
    SelectBenefitIcon,
    SelectCatalog,
    SelectCategory,
    SelectProduct,
    SelectScreenTabs,
    Textarea,
    CustomizeColumns,
    CustomizeColumnsTabs,
    CustomizeColumnsRange,
    InputNumber,
    CustomizePadding,
    CustomizePaddingTabs,
    CustomizeBorderRadiusTabs,
    CustomizeBackground,
    CustomizeBackgroundTabs,
    CustomizeMarginTabs,
    SelectBackground,
    InputIcon,
    SelectPreviewSizeList,
    CustomizeMedia,
    CustomizeMediaTabs,
    PageStatus,
    InputSectionLayout,
    InputNumberAndRange,
    InputNumberAndRangeScreen,
    InputPaste,
    InputAlign,
};
