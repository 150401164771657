import { uuid } from 'vue-uuid';
import OptionValue from './OptionValue.js';
import initFields from '@/entities/option/Option.fields';
import { uniqBy } from 'lodash';
/**
 * Класс экземпляра опции
 */
export default class Option {
    type = 'option';

    /**
     *
     * @param {Object}  data - объект данных опции
     */
    constructor({
        id = uuid.v4(),
        title = '',
        position = 0,
        view = 'buttons',
        type = 'string',
        unit = '',
        useInFilter = true,
        values = [],
    } = {}) {
        this.id = id;
        this.title = title;
        this.position = position;
        this.view = view;
        this.type = type;
        this.useInFilter = useInFilter === true || useInFilter === 'useInFilter' ? 'useInFilter' : 'doNotUseInFilter';
        this.unit = unit;
        this.values = values.map((value) => new OptionValue(value));
        this.fields = initFields(this);
    }

    get data() {
        return {
            id: this.id,
            title: this.title,
            position: this.position,
            view: this.view,
            type: this.type,
            useInFilter: this.useInFilter === 'useInFilter',
            unit: this.unit,
            values: this.values.map((optionValue) => optionValue.data),
        };
    }

    /**
     * Добавляем значение опции
     * @param {Object} valueData - объект с данными значения опции
     */
    addValue(valueData) {
        this.values.push(new OptionValue(valueData));
    }

    onChangeOptionValue(values) {
        const optionValues = values.map((value) => {
            return typeof value === 'object' ? new OptionValue(value) : new OptionValue({ title: value });
        });
        this.values = uniqBy(optionValues, 'title');
    }
}
