<template>
    <div v-if="translationsLoaded && isDataLoaded" id="adminpro">
        <router-view :key="$route.params.id" />
        <deletion-modal
            v-model="deletion.open"
            :entity="deletion.entity"
            :id="deletion.id"
            :deleteAll="deletion.deleteAll"
        ></deletion-modal>
        <custom-noty />
    </div>
</template>

<script>
import CustomNoty from '@/components/common/CustomNoty.vue';
import DeletionModal from '@/components/common/DeletionModal';
import { mapState, mapActions, mapGetters } from 'vuex';
import { getAccessToken } from '@/api/auth';
import { localStorageService } from '@/helpers/localStorageService';
// import { fetchAuthorizationCode } from '@/api/auth';

export default {
    name: 'App',
    components: {
        DeletionModal,
        CustomNoty,
    },
    data() {
        return {
            // translationsLoaded: false,
            translationsLoaded: true,
            accessGranted: false,
            isDataLoaded: false,
        };
    },
    async created() {
        if (window.Cypress) {
            // only available during E2E tests
            window.app = this;
        }
        await getAccessToken()
            .then(async () => {
                await this.loadUser();

                // TODO: Если пользователь не активирован, то надо вывести предупреждение
                // if (!this.currentUser || !this.currentUser?.activated) this.redirectToAccount();

                // проверяем доступность текущего инстанса для текущего пользователя
                await this.loadInstance();
                const error = await this.loadInstances();
                if (error) return;
                const instanceIds = this.instances.map((item) => item.instanceId);
                // если не доступен, то перекидываем на страницу выбора инстансов
                if (!instanceIds.includes(this.instance) && this.$route.name !== 'Instance List')
                    await this.$router.push({ name: 'Instance List' });
                else if (this.instance) {
                    // запрашиваем настройки
                    await this.$store.dispatch('config/loadSettings');
                }
                this.accessGranted = true;
            })
            .catch(() => {
                console.log('[App] No auth. Skip loading settings');
            });

        // обрабатываем переводы
        await this.loadTranslate();
        const locale = localStorage.getItem('locale') || process.env.VUE_APP_DEFAULT_LANGUAGE;
        if (locale) this.$i18n.locale = locale;

        localStorageService.subscribe('runshop_instance_id', (instance_id) => {
            console.log('instance_id update to ', instance_id);
            window.location.href = '/';
        });
        // const instanceId = localStorage.getItem('runshop_instance_id');
        // if (!instanceId) router.push('Instance List');
        this.isDataLoaded = true;
    },
    computed: {
        ...mapState('modals', ['deletion']),
        ...mapState('globalVars', ['currentUser', 'instance']),
        ...mapGetters('globalVars', { instances: 'getInstances' }),
        currentRouteName() {
            return this.$route.name;
        },
        userRole() {
            return this.$store.state.auth.role;
        },
    },
    methods: {
        async loadTranslate() {
            try {
                const messages = await import('@/translations/messages');
                this.$i18n.setLocaleMessage('en', messages.default.en);
                this.translationsLoaded = true;
            } catch (error) {
                console.error(error);
            }
        },
        ...mapActions('globalVars', {
            loadInstance: 'loadInstance',
            loadInstances: 'loadInstances',
            loadUser: 'loadUser',
        }),
    },
};
</script>
<style lang="scss">
@import './scss/index.scss';
@import './scss/stylesVuetify/vuetify.css';
</style>
